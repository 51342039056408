import PostGraphileApi from "../../utils/PostGraphileApi"

import * as turf from "@turf/turf" // GIS/GeoJSON library

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
dayjs.extend(utc)

function pathfinderResponseToRouteFormat(pathfinderRoutes) {
  const waypoints = pathfinderRoutes.baseRoute.wps
  // Use a single .map to generate both LineString and Point features
  const lineAndPointFeatures = waypoints.map((wp, index, array) => {
    // Convert Unix timestamp to ISO 8601 format
    const isoTimestamp = wp.timestamp ? dayjs.unix(wp.timestamp).utc().format("YYYY-MM-DDTHH:mm:ss[+00:00]") : null
    // Create Point feature for the current waypoint
    const point = turf.point([wp.lon, wp.lat], {
      dmean: wp.dmean,
      hssw: wp.hssw,
      legType: wp.legType,
      timestamp: isoTimestamp,
      u10: wp.u10,
      ucur: wp.ucur,
      v10: wp.v10,
      vcur: wp.vcur,
    })

    // Create LineString feature, ensuring we're not on the last waypoint
    const line =
      index < array.length - 1
        ? turf.lineString(
            [
              [wp.lon, wp.lat],
              [array[index + 1].lon, array[index + 1].lat],
            ],
            {
              analyse: {
                wp1: point,
                wp2: turf.point([array[index + 1].lon, array[index + 1].lat], {
                  dmean: array[index + 1].dmean,
                  hssw: array[index + 1].hssw,
                  legType: array[index + 1].legType,
                  timestamp: isoTimestamp,
                  u10: array[index + 1].u10,
                  ucur: array[index + 1].ucur,
                  v10: array[index + 1].v10,
                  vcur: array[index + 1].vcur,
                }),
              },
            }
          )
        : null

    return { point, line }
  })

  // Construct the final object with all steps included in the return statement
  return [
    {
      points: {
        geojson: turf.featureCollection(lineAndPointFeatures.map((item) => item.point)),
        routeId: "Pathfinder",
        routeType: "Pathfinder",
        vesselName: "Pathfinder",
      },
      lines: {
        geojson: turf.featureCollection(lineAndPointFeatures.map((item) => item.line).filter(Boolean)),
        routeId: "Pathfinder",
        routeType: "Pathfinder",
        vesselName: "Pathfinder",
      },
    },
  ]
}

// Main function to orchestrate voyage and route insertion
export default async function Pathfinder({
  FLASK_URL,
  accessToken,
  voyagePlannerPoints,
  calculatorDepartureTime,
  calculatorBasespeed,
  setLoadingPathfinder,
  setPathfinderRoutes,
  setEtaResponse,
}) {
  const PATHFINDER_URL = FLASK_URL + "pathfinder"
  // console.log("voyagePlannerPoints", voyagePlannerPoints)
  if (voyagePlannerPoints.features.length < 2) {
    return
  }
  // console.log(dayjs(calculatorDepartureTime).utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ"))
  const assetPath = "/home/ww-admin/rust/astar/src/assets/"
  const importPath = "/home/ww-admin/data/"
  const departureTime = `${dayjs(calculatorDepartureTime).utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ")}`
  const departurePoint = `[${voyagePlannerPoints.features[0].geometry.coordinates}]`
  const arrivalPoint = `[${voyagePlannerPoints.features[1].geometry.coordinates}]`
  const basespeedMsec = parseInt(calculatorBasespeed, 10)
  const data = `{"departure":{"lonlat":${departurePoint},"time":"${departureTime}"},"arrival":{"lonlat":${arrivalPoint}},"weights":{"distance":0.0,"time":1.0},"vessel":{"basespeed_msec":${basespeedMsec}},"water_layer":{"shallowwater_danger_basespeed_factor_malus":0.5},"wind_layer":{"speedprofile_rows_angles":[0.0,45.0,90.0,135.0,180.0,225.0,270.0,315.0,360.0],"speedprofile_columns_strengths":[0.0,5.0,10.0,15.0,20.0,25.0],"speedprofile_percent_speeds":[[100.0,100.0,100.0,95.0,85.0,75.0],[100.0,100.0,100.0,100.0,90.0,85.0],[100.0,100.0,100.0,100.0,100.0,100.0],[100.0,100.0,100.0,100.0,102.0,105.0],[100.0,100.0,100.0,102.0,105.0,110.0],[100.0,100.0,100.0,100.0,102.0,105.0],[100.0,100.0,100.0,100.0,100.0,100.0],[100.0,100.0,100.0,100.0,90.0,85.0],[100.0,100.0,100.0,95.0,85.0,75.0]],"file":"GFS/GFS_U_V_merged.nc","active":true,"danger_greater_kmh":50.0},"wave_layer":{"speedprofile_rows_angles":[0.0,45.0,90.0,135.0,180.0,225.0,270.0,315.0,360.0],"speedprofile_columns_strengths":[0.0,1.0,2.0,3.0,4.0,5.0],"speedprofile_percent_speeds":[[100.0,100.0,100.0,95.0,85.0,75.0],[100.0,100.0,100.0,100.0,90.0,85.0],[100.0,100.0,100.0,100.0,100.0,100.0],[100.0,100.0,100.0,100.0,102.0,105.0],[100.0,100.0,100.0,102.0,105.0,110.0],[100.0,100.0,100.0,100.0,102.0,105.0],[100.0,100.0,100.0,100.0,100.0,100.0],[100.0,100.0,100.0,100.0,90.0,85.0],[100.0,100.0,100.0,95.0,85.0,75.0]],"file":"NWW3/NWW3_W-HSSW_W-DMEAN_merged.nc","active":true,"danger_greater_meter":4.0},"current_layer":{"file":"HYCOM/HYCOM_UCUR_VCUR_merged.nc","active":true,"danger_greater_kmh":10.0},"routeanalysis":{"waypoints":false},"config":{"asset_path":"${assetPath}","import_path":"${importPath}","visualize":"","time_heuristic":true,"distance_heuristic":false,"weather_danger_greater_basespeed_factor_malus":0.05,"ocean_resolution":"8000"}}`
  // const data = `/home/ww-admin/rust/astar/target/release/astar '{"departure":{"lonlat":${departurePoint},"time":"${departureTime}"},"arrival":{"lonlat":${arrivalPoint}},"weights":{"distance":0.0,"time":1.0},"vessel":{"basespeed_msec":${basespeedMsec}},"water_layer":{"shallowwater_malus":15.0},"wind_layer":{"speedprofile_rows_angles":[0.0,45.0,90.0,135.0,180.0,225.0,270.0,315.0,360.0],"speedprofile_columns_strengths":[0.0,5.0,10.0,15.0,20.0,25.0],"speedprofile_percent_speeds":[[100.0,100.0,100.0,95.0,85.0,75.0],[100.0,100.0,100.0,100.0,90.0,85.0],[100.0,100.0,100.0,100.0,100.0,100.0],[100.0,100.0,100.0,100.0,102.0,105.0],[100.0,100.0,100.0,102.0,105.0,110.0],[100.0,100.0,100.0,100.0,102.0,105.0],[100.0,100.0,100.0,100.0,100.0,100.0],[100.0,100.0,100.0,100.0,90.0,85.0],[100.0,100.0,100.0,95.0,85.0,75.0]],"file":"GFS/GFS_U_V_merged.nc","active":true,"danger_greater_kmh":50.0,"danger_greater_malus":1.0},"wave_layer":{"speedprofile_rows_angles":[0.0,45.0,90.0,135.0,180.0,225.0,270.0,315.0,360.0],"speedprofile_columns_strengths":[0.0,1.0,2.0,3.0,4.0,5.0],"speedprofile_percent_speeds":[[100.0,100.0,100.0,95.0,85.0,75.0],[100.0,100.0,100.0,100.0,90.0,85.0],[100.0,100.0,100.0,100.0,100.0,100.0],[100.0,100.0,100.0,100.0,102.0,105.0],[100.0,100.0,100.0,102.0,105.0,110.0],[100.0,100.0,100.0,100.0,102.0,105.0],[100.0,100.0,100.0,100.0,100.0,100.0],[100.0,100.0,100.0,100.0,90.0,85.0],[100.0,100.0,100.0,95.0,85.0,75.0]],"file":"NWW3/NWW3_W-HSSW_W-DMEAN_merged.nc","active":true,"danger_greater_meter":4.0,"danger_greater_malus":1.0},"current_layer":{"file":"HYCOM/HYCOM_UCUR_VCUR_merged.nc","active":true,"danger_greater_kmh":75.0,"danger_greater_malus":1.0},"routeanalysis":{"waypoints":false},"config":{"asset_path":"${assetPath}","import_path":"${importPath}"}}'`
  // PostGraphileApi.fetchRequest("https://uss-01.local:8080/pathfinder", request, "Error: ")
  //   .catch((error) => {
  //     setLoadingPathfinder(false)
  //     console.error("Request failed:", error)
  //   })

  // const request = {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: data,
  // }
  // let accessToken = btoa(`${accessToken.api.username}:${accessToken.api.password}`)
  let headers = {
    Accept: "application/json", // response data format
    "Content-Type": "application/json", // request body data format
    "Accept-Encoding": "gzip, deflate", // kann fehlen, der browser erkennt's trotzdem
    Authorization: "Bearer " + accessToken, // Replace with actual token or omit if not needed
  }

  const request = {
    method: "POST",
    headers: headers,
    body: data,
  }
  console.log(PATHFINDER_URL, request)
  PostGraphileApi.fetchRequest(PATHFINDER_URL, request, "Error: ")
    .then((response) => {
      setLoadingPathfinder(false)
      let convertedPathfinderRoute = pathfinderResponseToRouteFormat(response)
      // console.log("setPathfinderRoutes", convertedPathfinderRoute)
      setPathfinderRoutes(convertedPathfinderRoute)
      setEtaResponse(
        dayjs(
          convertedPathfinderRoute[0].points.geojson.features[
            convertedPathfinderRoute[0].points.geojson.features.length - 1
          ].properties.timestamp
        ).format("YYYY-MM-DD HH:mm:ss")
      )
    })
    .catch((error) => {
      setLoadingPathfinder(false)
      console.error("Request failed:", error)
    })
}
