import * as React from "react"
import { useRef, useState, useEffect, useMemo, Profiler } from "react"
import { LayerGroup, useMap } from "react-leaflet"
import "leaflet/dist/leaflet.css"
import waterTemperatureIcon from "../../assets/images/parameter/temperature-control.png"
import windIcon from "../../assets/images/parameter/wind.png"
import waveIcon from "../../assets/images/parameter/wave.png"
import currentIcon from "../../assets/images/parameter/current.png"

import { Paper, Select } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"

import RoutePoints from "./RoutePoints"
import RouteLines from "./RouteLines"
import RouteForecast from "./RouteForecast"
import RouteReanalyse from "./RouteReanalyse"
import Legend from "../meteo/legend"
import WMSLegend from "../weatherlayers/WMSLegend"
import Helper from "../../utils/Helper"

// const colorBars = {
//   W_HSSW: [
//     { color: "white", text: "0-0.5", values: [0, 0.5] },
//     { color: "#e2f9f7", text: "0.5-1", values: [0.5, 1] },
//     { color: "#b1f0e9", text: "1-1.5", values: [1, 1.5] },
//     { color: "#9aece2", text: "1.5-2", values: [1.5, 2] },
//     { color: "#69decc", text: "2-2.5", values: [2, 2.5] },
//     { color: "#85b9f1", text: "2.5-3", values: [2.5, 3] },
//     { color: "#66a4ed", text: "3-3.5", values: [3, 3.5] },
//     { color: "#4d85e7", text: "3.5-4", values: [3.5, 4] },
//     { color: "#f5df22", text: "4-4.5", values: [4, 4.5] },
//     { color: "#f1ba1e", text: "4.5-5", values: [4.5, 5] },
//     { color: "#ee9656", text: "5-6", values: [5, 6] },
//     { color: "#eb6055", text: "6-7", values: [6, 7] },
//     { color: "#d34a3c", text: "7-8", values: [7, 8] },
//     { color: "#bb3524", text: "8-9", values: [8, 9] },
//     { color: "#741605", text: "9-10", values: [9, 10] },
//     { color: "#ff4bff", text: ">10", values: [10] },
//   ],
//   CurrentSpeed: [
//     { color: "white", text: "0-0.25", values: [0, 0.25] },
//     { color: "#c6e6bf", text: "0.25-0.5", values: [0.25, 0.5] },
//     { color: "#99d993", text: "0.5-0.75", values: [0.5, 1] },
//     { color: "#6cbf6c", text: "0.75-1", values: [0.75, 1] },
//     { color: "#ffff40", text: "1-1.25", values: [1, 1.25] },
//     { color: "#ffbf2d", text: "1.25-1.5", values: [1.25, 1.5] },
//     { color: "#f9802d", text: "1.5-1.75", values: [1.5, 1.75] },
//     { color: "#d9332d", text: "1.75-2", values: [1.75, 2] },
//     { color: "#a61a26", text: "2-2.25", values: [2, 2.25] },
//     { color: "#730d20", text: "2.25-2.5", values: [2.25, 2.5] },
//     { color: "#46001a", text: "2.5-2.75", values: [2.5, 2.75] },
//     { color: "#8f2a8f", text: "2.75-3", values: [2.75, 3] },
//     { color: "#ff4bff", text: ">3", values: [3] },
//   ],
//   WindSpeed: [
//     { color: "white", text: "0-4", values: [0, 4] },
//     { color: "#9fcce6", text: "4-8", values: [4, 8] },
//     { color: "#3386bf", text: "8-12", values: [8, 12] },
//     { color: "#33a653", text: "12-16", values: [12, 16] },
//     { color: "#79c679", text: "16-20", values: [16, 20] },
//     { color: "#fff24d", text: "20-24", values: [20, 24] },
//     { color: "#ffb34d", text: "24-30", values: [24, 30] },
//     { color: "#ff6626", text: "30-34", values: [30, 34] },
//     { color: "#e61a1a", text: "34-38", values: [34, 38] },
//     { color: "#b31a6c", text: "38-42", values: [38, 42] },
//     { color: "#66006c", text: ">42", values: [42] },
//   ],
//   SST: [
//     { color: "#000d40", text: "<0", values: [0] },
//     { color: "#002066", text: "0-2", values: [0, 2] },
//     { color: "#004d93", text: "2-4", values: [2, 4] },
//     { color: "#0093cc", text: "4-6", values: [4, 6] },
//     { color: "#00b9d9", text: "6-8", values: [6, 8] },
//     { color: "#00ac79", text: "8-10", values: [8, 10] },
//     { color: "#009f13", text: "10-12", values: [10, 12] },
//     { color: "#53b900", text: "12-14", values: [12, 14] },
//     { color: "#acdf00", text: "14-16", values: [14, 16] },
//     { color: "#f9ec00", text: "16-18", values: [16, 18] },
//     { color: "#e6c001", text: "18-20", values: [18, 20] },
//     { color: "#e68600", text: "20-22", values: [20, 22] },
//     { color: "#d22600", text: "22-24", values: [22, 24] },
//     { color: "#a60000", text: "24-26", values: [24, 26] },
//     { color: "#660000", text: "26-28", values: [26, 28] },
//     { color: "#330000", text: ">28", values: [28] },
//   ],
// }
const colorBars = {
  "NWW3:WHSSW": [
    { color: "#f0ffff", text: "0", values: [0, 0.5] },
    { color: "#afeeee", text: "0.5", values: [0.5, 1] },
    { color: "#00ffff", text: "1", values: [1, 1.5] },
    { color: "#87cefa", text: "1.5", values: [1.5, 2] },
    { color: "#32cd32", text: "2", values: [2, 2.5] },
    { color: "#00ff00", text: "2.5", values: [2.5, 3] },
    { color: "#adff2f", text: "3", values: [3, 3.5] },
    { color: "#ffff00", text: "3.5", values: [3.5, 4] },
    { color: "#eee8aa", text: "4", values: [4, 4.5] },
    { color: "#ffd700", text: "5", values: [4.5, 5] },
    { color: "#daa520", text: "6", values: [5, 6] },
    { color: "#ffa500", text: "7", values: [6, 7] },
    { color: "#ff8c00", text: "8", values: [7, 8] },
    { color: "#ff6347", text: "9", values: [8, 9] },
    { color: "#ff0000", text: "10", values: [9, 10] },
  ],
  CurrentSpeed: [
    { color: "white", text: "0-0.25", values: [0, 0.25] },
    { color: "#c6e6bf", text: "0.25-0.5", values: [0.25, 0.5] },
    { color: "#99d993", text: "0.5-0.75", values: [0.5, 1] },
    { color: "#6cbf6c", text: "0.75-1", values: [0.75, 1] },
    { color: "#ffff40", text: "1-1.25", values: [1, 1.25] },
    { color: "#ffbf2d", text: "1.25-1.5", values: [1.25, 1.5] },
    { color: "#f9802d", text: "1.5-1.75", values: [1.5, 1.75] },
    { color: "#d9332d", text: "1.75-2", values: [1.75, 2] },
    { color: "#a61a26", text: "2-2.25", values: [2, 2.25] },
    { color: "#730d20", text: "2.25-2.5", values: [2.25, 2.5] },
    { color: "#46001a", text: "2.5-2.75", values: [2.5, 2.75] },
    { color: "#8f2a8f", text: "2.75-3", values: [2.75, 3] },
    { color: "#ff4bff", text: ">3", values: [3] },
  ],
  "GFS:FFG": [
    { color: "#ffffff", text: "0", values: [0, 5] },
    { color: "#ffffff", text: "5", values: [5, 10] },
    { color: "#fffaf0", text: "10", values: [10, 15] },
    { color: "#adff2f", text: "15", values: [15, 20] },
    { color: "#90ee90", text: "20", values: [20, 25] },
    { color: "#00fa9a", text: "25", values: [25, 30] },
    { color: "#32cd32", text: "30", values: [30, 35] },
    { color: "#ffff00", text: "35", values: [35, 40] },
    { color: "#ffa500", text: "40", values: [40, 45] },
    { color: "#f08080", text: "45", values: [45, 50] },
    { color: "#ee82ee", text: "50", values: [50, 55] },
    { color: "#ff0000", text: "55", values: [55, 60] },
    { color: "#8b0000", text: "60", values: [60, 65] },
    { color: "#a020f0", text: ">60", values: [65] },
  ],
  "COP:SST": [
    { color: "#0000ff", text: "<-20", values: [-20] },
    { color: "#4682b4", text: "-20", values: [-20, -18] },
    { color: "#00bfff", text: "-18", values: [-18, -16] },
    { color: "#87cefa", text: "-16", values: [-16, -14] },
    { color: "#00ffff", text: "-14", values: [-14, -12] },
    { color: "#ee82ee", text: "-12", values: [-12, -10] },
    { color: "#ff69b4", text: "-10", values: [-10, -8] },
    { color: "#db7093", text: "-8", values: [-8, -6] },
    { color: "#ffc0cb", text: "-6", values: [-6, -4] },
    { color: "#d0d0d0", text: "-4", values: [-4, -2] },
    { color: "#dcdcdc", text: "-2", values: [-2, 0] },
    { color: "#ffffff", text: "0", values: [0, 2] },
    { color: "#f0e68c", text: "2", values: [2, 4] },
    { color: "#bdb76b", text: "4", values: [4, 6] },
    { color: "#808000", text: "6", values: [6, 8] },
    { color: "#228b22", text: "8", values: [8, 10] },
    { color: "#32cd32", text: "10", values: [10, 12] },
    { color: "#00ff00", text: "12", values: [12, 14] },
    { color: "#adff2f", text: "14", values: [14, 16] },
    { color: "#ffff00", text: "16", values: [16, 18] },
    { color: "#ffd700", text: "18", values: [18, 20] },
    { color: "#daa520", text: "20", values: [20, 22] },
    { color: "#f5deb3", text: "22", values: [22, 24] },
    { color: "#deb887", text: "24", values: [24, 26] },
    { color: "#d2691e", text: "26", values: [26, 28] },
    { color: "#ffa500", text: "28", values: [28, 30] },
    { color: "#ff8c00", text: "30", values: [30, 32] },
    { color: "#ff6347", text: "32", values: [32, 34] },
    { color: "#ff0000", text: "34", values: [34, 36] },
    { color: "#b22222", text: "36", values: [36, 38] },
    { color: "#8b0000", text: "38", values: [38, 40] },
    { color: "#6a5acd", text: "40", values: [40, 42] },
    { color: "#a020f0", text: ">40", values: [42] },
  ],
}

export default function RouteController({
  METEO_URL,
  GEOSERVER_URL,
  mapBounds,
  vesselRealRoutes,
  vesselPlanRoutes,
  vesselSuggestRoutes,
  switchReported,
  switchPlan,
  switchSuggest,
  switchForecast,
  switchReanalyse,
  fromPicker,
  toPicker,
  setFetchingForecast,
  setFetchingReanalyse,
  drawerWidth,
  openDrawer,
  resized,
}) {
  const map = useMap()
  // creating a reference to paper component width to pass the value into legend component
  const [legendWidth, setLegendWidth] = useState(null)
  const paperRef = useRef(null)
  useEffect(() => {
    if (paperRef.current) {
      setLegendWidth(paperRef.current.offsetWidth)
    }
  }, [openDrawer, drawerWidth, switchForecast, switchReanalyse, resized])
  // calculating legendPaperWidth related to drawer (57px is width of closed drawer)
  const legendPaperWidth = openDrawer ? `calc(100% - ${drawerWidth}px)` : `calc(100% - 57px)`

  const [selectedParameter, setSelectedParameter] = useState("NWW3:WHSSW")
  const colorObject = colorBars[selectedParameter]
  const layers = []
  const legend = []

  const handleChange = (event) => {
    setSelectedParameter(event.target.value)
    if (!map) return
    map.dragging.enable()
  }

  function onRenderCallback(id, phase, actualDuration, baseDuration, startTime, commitTime, interactions) {
    console.log(`${id} took: ${actualDuration} ms`)
  }

  const MemoizedPlanRoutes = useMemo(() => {
    // console.log("plan", vesselPlanRoutes)
    if (switchPlan && vesselPlanRoutes) {
      return (
        <LayerGroup key={"plan"}>
          <RoutePoints vesselRoutes={vesselPlanRoutes} mapBounds={mapBounds} />
          <RouteLines vesselRoutes={vesselPlanRoutes} mapBounds={mapBounds} />
        </LayerGroup>
      )
    }
  }, [switchPlan, vesselRealRoutes, mapBounds, switchForecast, vesselPlanRoutes])
  if (switchPlan && vesselPlanRoutes) {
    layers.push(MemoizedPlanRoutes)
  }

  const MemoizedReportRoutes = useMemo(() => {
    // console.log("Report", vesselRealRoutes)
    if (switchReported && vesselRealRoutes) {
      return (
        <LayerGroup key={"Report"}>
          <RoutePoints vesselRoutes={vesselRealRoutes} mapBounds={mapBounds} />
          <RouteLines vesselRoutes={vesselRealRoutes} mapBounds={mapBounds} />
        </LayerGroup>
      )
    }
  }, [switchReported, vesselRealRoutes, mapBounds, switchReanalyse])
  if (switchReported && vesselRealRoutes) {
    layers.push(MemoizedReportRoutes)
  }

  // if (switchPlan && vesselPlanRoutes) {
  //   console.log('plan');
  //   layers.push(
  //     <LayerGroup>
  //       <RoutePoints
  //         vesselRoutes={vesselPlanRoutes}
  //         mapBounds={mapBounds}
  //         fromPicker={fromPicker}
  //         toPicker={toPicker}
  //       />
  //       <RouteLines
  //         vesselRoutes={vesselPlanRoutes}
  //         mapBounds={mapBounds}
  //         fromPicker={fromPicker}
  //         toPicker={toPicker}
  //       />
  //     </LayerGroup>
  //   )
  // }
  // if (switchSuggest) {
  //   layers.push(
  //     <LayerGroup>
  //       <RoutePoints
  //         key="RoutePointsSuggest"
  //         vesselRoutes={vesselSuggestRoutes}
  //         fromPicker={fromPicker}
  //         toPicker={toPicker}
  //       />
  //       <RouteLines
  //         key="RouteLinesSuggest"
  //         vesselRoutes={vesselSuggestRoutes}
  //         fromPicker={fromPicker}
  //         toPicker={toPicker}
  //       />
  //     </LayerGroup>
  //   )
  // }
  // if (switchReported && vesselRealRoutes) {
  //   console.log('real');
  //   layers.push(
  //     <LayerGroup>
  //       {/* <Profiler id="RoutePointsReported" onRender={onRenderCallback}> */}
  //         <RoutePoints
  //           vesselRoutes={vesselRealRoutes}
  //           mapBounds={mapBounds}
  //           fromPicker={fromPicker}
  //           toPicker={toPicker}
  //         />
  //       {/* </Profiler>,[] */}
  //       {/* <Profiler id="RouteLinesReported" onRender={onRenderCallback}> */}
  //         <RouteLines
  //           vesselRoutes={vesselRealRoutes}
  //           mapBounds={mapBounds}
  //           fromPicker={fromPicker}
  //           toPicker={toPicker}
  //         />
  //       {/* </Profiler>,[] */}
  //     </LayerGroup>
  //   )
  // }

  const forecastRoutes = useMemo(() => {
    let routes = []
    if (switchPlan) {
      routes = [...routes, ...vesselPlanRoutes]
    }
    if (switchSuggest) {
      routes = [...routes, ...vesselSuggestRoutes]
    }
    return routes
  }, [switchPlan, vesselPlanRoutes, switchSuggest, vesselSuggestRoutes])
  if (switchForecast && forecastRoutes) {
    layers.push(
      <LayerGroup key={`routeForecast`}>
        <RouteForecast
          METEO_URL={METEO_URL}
          forecastRoutes={forecastRoutes}
          switchForecast={switchForecast}
          fromPicker={fromPicker}
          toPicker={toPicker}
          selectedParameter={selectedParameter}
          colorObject={colorObject}
          setFetchingForecast={setFetchingForecast}
        />
      </LayerGroup>
    )
  }
  // const MemoizedForecastRoutes = useMemo(() => {
  //   let routes = []
  //   if (switchPlan) {
  //     routes = [...routes, ...vesselPlanRoutes]
  //   }
  //   if (switchSuggest) {
  //     routes = [...routes, ...vesselSuggestRoutes]
  //   }
  //   return (
  //     <LayerGroup key={`routeForecast`}>
  //         <RouteForecast
  //           METEO_URL={METEO_URL}
  //           forecastRoutes={routes}
  //           switchForecast={switchForecast}
  //           fromPicker={fromPicker}
  //           toPicker={toPicker}
  //           selectedParameter={selectedParameter}
  //           colorObject={colorObject}
  //           setFetchingForecast={setFetchingForecast}
  //         />
  //     </LayerGroup>)
  // }, [switchPlan, vesselPlanRoutes, switchSuggest, vesselSuggestRoutes, selectedParameter])
  // if (switchForecast && MemoizedForecastRoutes) {
  //   layers.push(MemoizedForecastRoutes)
  // }

  if (switchReanalyse && vesselRealRoutes) {
    layers.push(
      <LayerGroup key={`routeReanalyse`}>
        {/* <Profiler id="Reanalyse weather" onRender={onRenderCallback}> */}
        <RouteReanalyse
          vesselRealRoutes={vesselRealRoutes}
          mapBounds={mapBounds}
          switchReanalyse={switchReanalyse}
          selectedParameter={selectedParameter}
          colorObject={colorObject}
          setFetchingReanalyse={setFetchingReanalyse}
        />
        {/* </Profiler>,[] */}
      </LayerGroup>
    )
  }

  if (switchForecast || switchReanalyse) {
    layers.push(
      <Paper
        ref={paperRef}
        sx={{
          position: "absolute",
          bottom: 0,
          zIndex: 1001,
          backgroundColor: "white",
          padding: "0px",
          borderRadius: "0px",
          boxShadow: "0 0 0px rgba(0,0,0,0)",
          width: legendPaperWidth,
          marginLeft: openDrawer ? `${drawerWidth}px` : `57px`,
          color: "#555",
        }}
        onMouseEnter={() => Helper.onMouseEnter(map)}
        onMouseLeave={() => Helper.onMouseLeave(map)}
      >
        {/* <Legend
          key={`legend`}
          activeSwitch={activeSwitch}
          selectedParameter={selectedParameter}
          setSelectedParameter={setSelectedParameter}
          colorObject={colorObject}
          drawerWidth={drawerWidth}
          openDrawer={openDrawer}
          legendWidth={legendWidth}
        /> */}
        <FormControl sx={{ minWidth: 120 }} size="small" variant="outlined">
          {/* <InputLabel id="demo-select-small-label">Parameter</InputLabel> */}
          <Select
            sx={{
              position: "absolute",
              bottom: 42,
              zIndex: 1000,
              backgroundColor: "white",
              padding: "0px",
              borderRadius: "5px",
              boxShadow: "0 0 0px rgba(0,0,0,0)",
              marginLeft: "10px",
              color: "#004f7d",
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              PaperProps: {
                style: {
                  transform: "translate3d(0, -67px, 0)", // Adjust the -65px value as needed
                },
              },
            }}
            onMouseEnter={() => Helper.onMouseEnter(map)}
            onMouseLeave={() => Helper.onMouseLeave(map)}
            onClose={() => Helper.onMouseLeave(map)}
            value={selectedParameter}
            onChange={handleChange}
            inputProps={{ "aria-label": "legend settings" }}
          >
            <MenuItem value={"NWW3:WHSSW"}>
              {" "}
              <img src={waveIcon} style={{ height: "25px", marginRight: "8px", verticalAlign: "middle" }} /> Wave Height
            </MenuItem>

            <MenuItem value={"CurrentSpeed"}>
              {" "}
              <img src={currentIcon} style={{ height: "20px", marginRight: "8px", verticalAlign: "middle" }} /> Current
            </MenuItem>
            <MenuItem value={"GFS:FFG"}>
              {" "}
              <img src={windIcon} style={{ height: "25px", marginRight: "8px", verticalAlign: "middle" }} /> Wind
            </MenuItem>
            <MenuItem value={"COP:SST"}>
              <img src={waterTemperatureIcon} style={{ height: "25px", marginRight: "8px", verticalAlign: "middle" }} />{" "}
              Sea Surface Temperature
            </MenuItem>
          </Select>
        </FormControl>
      </Paper>
    )

    let legendUrl
    let styleName
    if (selectedParameter === "NWW3:WHSSW") {
      legendUrl = `${GEOSERVER_URL}NWW3/wms?REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&LAYER=WHSSW_RColor&STYLE=WHSSW_RColor_Inter_3857&WIDTH=20&HEIGHT=1`
      styleName = " WHSSW_RColor_3857"
    } else if (selectedParameter === "GFS:FFG") {
      legendUrl = `${GEOSERVER_URL}GFS/wms?REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&LAYER=FFG_RColor&STYLE=FFG_KN_RColor_Inter_3857&WIDTH=20&HEIGHT=1`
      styleName = "FFG_KN_RColor_3857"
    } else if (selectedParameter === "COP:SST") {
      legendUrl = `${GEOSERVER_URL}COP/wms?REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&LAYER=SST_RColor&STYLE=SST_DegC_RColor_Inter_3857&WIDTH=20&HEIGHT=1`
      styleName = "SST_DegC_RColor_3857"
    } else if (selectedParameter === "CurrentSpeed") {
      legendUrl = `${GEOSERVER_URL}GFS/wms?REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&LAYER=FFG_RColor&STYLE=FFG_KN_RColor_Inter_3857&WIDTH=20&HEIGHT=1`
    }
    legend.push(
      <WMSLegend
        key={legendUrl}
        legendUrl={legendUrl}
        drawerWidth={drawerWidth}
        openDrawer={openDrawer}
        layerName={selectedParameter}
        styleName={styleName}
      />
    )
  }

  return (
    <>
      {layers}
      <div className="legends-container">{legend}</div>
    </>
  )
}
