import L, { Icon } from "leaflet"
import NavigationIcon from "@mui/icons-material/Navigation"
import CircleIcon from "@mui/icons-material/Circle"
import ReactDOMServer from "react-dom/server"

import * as turf from "@turf/turf"

import shipMarkerIconBluePng from "../assets/images/vessel/marker-icon-boat-blue_intransparent.png"
import shipMarkerIconRedPng from "../assets/images/vessel/marker-icon-boat-red.png"
import shipMarkerIconGreenPng from "../assets/images/vessel/marker-icon-boat-green.png"
import shipMarkerIconPinkPng from "../assets/images/vessel/marker-icon-boat-pink.png"

import Helper from "./Helper"
import { RouteAnalyser } from "../modules/route_analyse/RouteAnalyser2"
import { fromJS } from "immutable"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import localizedFormat from "dayjs/plugin/localizedFormat"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"

dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const shipMarkerIcon = {
  // blue: new Icon({
  //   iconUrl: shipMarkerIconBluePng,
  //   iconSize: [0.6 * 25, 0.6 * 41], // size of the icon, original size!!! nullpunkt oben links!!!
  //   iconAnchor: [0.5 * 0.6 * 25, 0.5 * 0.6 * 41], // point of the icon which will correspond to marker's location, skaliert mit 05 und 0.25!!!
  // }),
  // blue_cross: L.divIcon({
  //   // Specify a class name we can refer to in CSS.
  //   className: "css-icon",
  //   html: '<img src="' + shipMarkerIconBluePng + '" class="ship-icon" /><div class="gps_ring"></div>',
  //   // Set marker width and height
  //   iconSize: [0.6 * 25, 0.6 * 41],
  //   iconAnchor: [0.5 * 0.6 * 25, 0.5 * 0.6 * 41],
  // }),
  // blue_cross: new Icon({
  //   iconUrl: shipMarkerIconBlueCrossPng,
  //   //iconSize: [0.5*25, 0.5*41], // size of the icon, original size!!! nullpunkt oben links!!!
  //   //iconAnchor: [0.25*25, 0.5*41] // point of the icon which will correspond to marker's location, skaliert mit 05 und 0.25!!!
  //   iconSize: [0.5 * 25, 0.5 * 41], // size of the icon, original size!!! nullpunkt oben links!!!
  //   iconAnchor: [0.5 * 0.5 * 25, 0.5 * 0.5 * 41], // point of the icon which will correspond to marker's location, skaliert mit 05 und 0.25!!!
  //   // shadowUrl, shadowSize, shadowAnchor, popupAnchor
  // }),
  blue: L.divIcon({
    className: "css-icon",
    html: ReactDOMServer.renderToString(
      <NavigationIcon style={{ color: "blue", fontSize: 30, stroke: "white", strokeWidth: 1 }} />
    ),
    iconAnchor: [0.5 * 30, 0.5 * 30],
  }),
  green: L.divIcon({
    className: "css-icon",
    html: ReactDOMServer.renderToString(
      <NavigationIcon style={{ color: "green", fontSize: 30, stroke: "white", strokeWidth: 1 }} />
    ),
    iconAnchor: [0.5 * 30, 0.5 * 30],
  }),
  blue_pulse: L.divIcon({
    className: "css-icon",
    html: ReactDOMServer.renderToString(
      <div>
        <NavigationIcon style={{ color: "blue", fontSize: 30, stroke: "white", strokeWidth: 1 }} />
        <div className="gps_ring_blue"></div>
      </div>
    ),
    iconAnchor: [0.5 * 30, 0.5 * 30],
  }),
  green_pulse: L.divIcon({
    className: "css-icon",
    html: ReactDOMServer.renderToString(
      <div>
        <NavigationIcon style={{ color: "green", fontSize: 30, stroke: "white", strokeWidth: 1 }} />
        <div className="gps_ring_green"></div>
      </div>
    ),
    iconAnchor: [0.5 * 30, 0.5 * 30],
  }),
  red: L.divIcon({
    className: "css-icon",
    html: ReactDOMServer.renderToString(
      <NavigationIcon style={{ color: "red", fontSize: 30, stroke: "white", strokeWidth: 1 }} />
    ),
    iconAnchor: [0.5 * 30, 0.5 * 30],
  }),
  purple: L.divIcon({
    className: "css-icon",
    html: ReactDOMServer.renderToString(
      <div>
        <NavigationIcon style={{ color: "purple", fontSize: 30, stroke: "white", strokeWidth: 1 }} />
        <div className="gps_ring_purple"></div>
      </div>
    ),
    iconAnchor: [0.5 * 30, 0.5 * 30],
  }),
}

const zoomWpNumberThreshold = {
  0: 200,
  1: 200,
  2: 200,
  3: 100,
  4: 100,
  5: 50,
  6: 50,
  7: 10,
  8: 10,
  9: 1,
  10: 1,
  11: 1,
  12: 1,
  13: 1,
  14: 1,
  15: 1,
  16: 1,
  17: 1,
  18: 1,
  19: 1,
}

const RouteHelper = {
  findRouteColor(routeType) {
    const routeType2Color = {
      real: "blue",
      real_pulse: "blue_pulse",
      plan: "green",
      plan_pulse: "green_pulse",
      suggest: "red",
      Pathfinder: "purple",
    }
    const color = routeType2Color[routeType]
    return color ? color : "green"
  },

  getRouteStyle(featureCollection, routeType) {
    featureCollection.features.map((feature) => {
      let dashArray = feature.properties === "dashed" ? "5,5" : "0"
      return {
        fillColor: RouteHelper.findRouteColor(routeType),
        color: RouteHelper.findRouteColor(routeType),
        weight: 2,
        opacity: "1",
        dashArray: dashArray, // specify the dash and gap lengths here
      }
    })
  },

  selectVesselIcon(route, markedVesselNames) {
    let vesselIconColor

    if ((route.routeType !== "real" && route.routeType !== "plan") || markedVesselNames.length === 0) {
      vesselIconColor = route.routeType
    } else if (markedVesselNames[markedVesselNames.length - 1].vesselName !== route.vesselName) {
      vesselIconColor = route.routeType
    } else if (
      route.routeType === "real" &&
      markedVesselNames[markedVesselNames.length - 1].vesselName === route.vesselName
    ) {
      vesselIconColor = "real_pulse"
    } else if (
      route.routeType === "plan" &&
      markedVesselNames[markedVesselNames.length - 1].vesselName === route.vesselName
    ) {
      vesselIconColor = "plan_pulse"
    }

    return shipMarkerIcon[RouteHelper.findRouteColor(vesselIconColor)]
  },

  renderPoint({
    route,
    markedVesselNames,
    switchReported,
    switchPlan,
    switchSuggest,
    fromPicker,
    sliderPosition,
    latlon,
    setCenterLatLonMap,
    setMarkedVesselNames,
    setOpenDrawer,
    setOpenVesselList,
  }) {
    // console.log("renderPoint", route.vesselName, route.routeType)
    let result
    if (switchReported || switchPlan || switchSuggest) {
      result = RouteHelper.renderSliderVesselIcon({ route, fromPicker, sliderPosition })
    } else {
      result = RouteHelper.renderVesselIcon({
        route,
        markedVesselNames,
        latlon,
        setCenterLatLonMap,
        setMarkedVesselNames,
        setOpenDrawer,
        setOpenVesselList,
      })
    }
    return result
  },

  // renderWpCircle(latlon) {
  //     return L.circleMarker(latlon, {
  //       radius: 2,
  //       opacity: 1, // border
  //       fillOpacity: 0.5, // fill
  //     })
  // },

  renderVesselIcon({
    route,
    markedVesselNames,
    latlon,
    setCenterLatLonMap,
    setMarkedVesselNames,
    setOpenDrawer,
    setOpenVesselList,
  }) {
    const pos_from = turf.point(route.geojson.features[route.geojson.features.length - 1].geometry.coordinates)
    for (let i = route.geojson.features.length - 2; i >= 0; i--) {
      const pos_to = turf.point(route.geojson.features[i].geometry.coordinates)
      if (turf.distance(pos_from, pos_to) >= 0.017) {
        const marker = L.rotatedMarker(latlon, {
          icon: RouteHelper.selectVesselIcon(route, markedVesselNames),
          interactive: true, //  mouse events aktiv
          rotationAngle:
            route.geojson.features.length > 1 // in grad
              ? (turf.bearing(
                  turf.point(route.geojson.features[i].geometry.coordinates),
                  turf.point(route.geojson.features[route.geojson.features.length - 1].geometry.coordinates)
                ) -
                  180) /
                2 // JK 12.7. eigentlich 180+turf.bearing... https://github.com/bbecquet/Leaflet.PolylineDecorator/issues/94
              : 0,
        })

        // Add the click event
        marker.on("click", () => {
          setCenterLatLonMap([latlon.lat, latlon.lng])

          const realRoutesEndpoints = [
            {
              vesselName: route.vesselName,
              routeType: route.routeType,
              lonlat: route.geojson.features[route.geojson.features.length - 1].geometry.coordinates,
            },
          ]

          if (setMarkedVesselNames) {
            setMarkedVesselNames(realRoutesEndpoints)
          }
          if (setOpenDrawer) {
            setOpenDrawer(true)
          }
          if (setOpenVesselList) {
            setOpenVesselList(true)
          }
        })

        return marker
      }
    }
  },

  renderSliderVesselIcon({ route, sliderPosition }) {
    if (sliderPosition.value === 0) {
      return RouteHelper.interpolateVesselPosition(route, 0, 1, sliderPosition)
    } else {
      for (let i = 0; i < route.geojson.features.length; i++) {
        if (
          route.routeType === "real" &&
          dayjs.utc(route.geojson.features[i].properties.timestamp).isSameOrAfter(sliderPosition.label)
          // verhindert Fehler wenn WP am Anfang fehlen
          // && dayjs.utc(route.geojson.features[0].properties.timestamp).isBefore(fromPicker)
        ) {
          return RouteHelper.interpolateVesselPosition(route, i - 1, i, sliderPosition)
        } else if (
          (route.routeType === "plan" || route.routeType === "suggest") &&
          dayjs.utc(route.geojson.features[i].properties.timestamp).isSameOrAfter(sliderPosition.label)
        ) {
          return RouteHelper.interpolateVesselPosition(route, i - 1, i, sliderPosition)
        }
      }
    }
  },

  interpolateVesselPosition(route, interpolationStartIndex, distanceCheckStartIndex, sliderPosition) {
    // console.log(
    //   "interpolateVesselPosition",
    //   route.vesselName,
    //   route.routeType,
    //   route.geojson.features[interpolationStartIndex].geometry.coordinates,
    //   route.geojson.features[distanceCheckStartIndex].geometry.coordinates
    // )
    if (route.geojson.features[interpolationStartIndex]) {
      let point1 = turf.point(route.geojson.features[interpolationStartIndex].geometry.coordinates)
      let point2 = null
      // check if distance between points > 100m
      for (let j = distanceCheckStartIndex; j < route.geojson.features.length; j++) {
        if (turf.distance(point1, turf.point(route.geojson.features[j].geometry.coordinates)) >= 0.017) {
          point2 = turf.point(route.geojson.features[j].geometry.coordinates)
          // Calculate distance, bearing, speed, and time difference
          const distance = turf.distance(point1, point2, { units: "kilometers" })
          const bearing = 180.0 + turf.bearing(point1, point2)
          // console.log(point1, point2, distance, bearing)
          const timeDiffInterp = Math.abs(
            (dayjs.utc(route.geojson.features[interpolationStartIndex].properties.timestamp) -
              dayjs.utc(sliderPosition.label)) /
              1000
          )
          const timeDiffP2P1 =
            (dayjs.utc(route.geojson.features[j].properties.timestamp) -
              dayjs.utc(route.geojson.features[interpolationStartIndex].properties.timestamp)) /
            1000
          const speed = distance / timeDiffP2P1

          // Determine legtype and return values
          if (route.geojson.features[interpolationStartIndex].properties.legtype === "rhumbline") {
            // Rhumbline
            const latlonAlongRoute = RouteHelper.getRhumblinePoint(point1, speed, timeDiffInterp, point2)
            const bearing = turf.rhumbBearing(point1, point2) - 180
            // console.log("interp rhumbline position", latlonAlongRoute, bearing)
            return { latlonAlongRoute, bearing }
          } else {
            // Greatcircle
            const latlonAlongRoute = RouteHelper.getGreatcirclePoint(
              point1,
              point2,
              speed,
              timeDiffInterp,
              timeDiffP2P1
            )
            // console.log("interp great circle position", latlonAlongRoute, bearing)
            return { latlonAlongRoute, bearing }
          }
        }
      }
      if (point2 === null) {
        // console.log(node.geojson.features[i].geometry.coordinates)
        const latlonAlongRoute = {
          lat: route.geojson.features[distanceCheckStartIndex].geometry.coordinates[1],
          lng: route.geojson.features[distanceCheckStartIndex].geometry.coordinates[0],
        }

        const bearing = null
        // console.log(route.vesselName, route.routeType, "dist between WPs too small to interpolate vessel position.")
        return { latlonAlongRoute, bearing }
      }
    }
  },

  getRhumblinePoint(point1, speed, timeDiffInterp, point2) {
    // what about dateline crossing? problems?
    const pointOnLine = turf.rhumbDestination(point1, speed * timeDiffInterp, turf.rhumbBearing(point1, point2), {
      units: "kilometers",
    })

    // Check if the longitude exceeds the valid range
    if (pointOnLine.geometry.coordinates[0] > 180) {
      pointOnLine.geometry.coordinates[0] -= 360
    } else if (pointOnLine.geometry.coordinates[0] < -180) {
      pointOnLine.geometry.coordinates[0] += 360
    }

    const latlonAlongRoute = {
      lat: pointOnLine.geometry.coordinates[1],
      lng: pointOnLine.geometry.coordinates[0],
    }
    return latlonAlongRoute
  },

  getGreatcirclePoint(point1, point2, speed, timeDiffInterp, timeDiffP2P1) {
    const lineRoute = turf.greatCircle(point1, point2)
    // if no dateline crossing (LineString) calculate and return point along great circle route
    if (lineRoute.geometry.type === "LineString") {
      const alongRoute = turf.along(lineRoute, speed * timeDiffInterp, { units: "kilometers" })
      const latlonAlongRoute = {
        lat: alongRoute.geometry.coordinates[1],
        lng: alongRoute.geometry.coordinates[0],
      }
      return latlonAlongRoute
    }
    // if dateline crossing (MultiLineString) then additionally check in which segment the point lies
    else if (lineRoute.geometry.type === "MultiLineString") {
      // console.log("greatCirclePoint", point1.geometry.coordinates, point2.geometry.coordinates, lineRoute)
      // const travelDistance = speed * timeDiffP2P1 // distance the vessel has traveled
      const travelDistance = turf.length(lineRoute, { units: "kilometers" })
      let accumulatedDistance = 0
      for (let i = 0; i < lineRoute.geometry.coordinates.length; i++) {
        const segment = turf.lineString(lineRoute.geometry.coordinates[i])
        const segmentDistance = turf.length(segment, { units: "kilometers" })
        // console.log(
        //   "mulitLinestring",
        //   travelDistance,
        //   accumulatedDistance,
        //   segmentDistance,
        //   accumulatedDistance + segmentDistance,
        //   accumulatedDistance + segmentDistance > travelDistance
        // )
        if (accumulatedDistance + segmentDistance > travelDistance) {
          // This is the segment on which the point lies
          const residualDistance = travelDistance - accumulatedDistance
          const alongSegment = turf.along(segment, residualDistance, { units: "kilometers" })
          // console.log("alongSegment", alongSegment)
          return {
            lat: alongSegment.geometry.coordinates[1],
            lng: alongSegment.geometry.coordinates[0],
          }
        } else if (accumulatedDistance + segmentDistance === travelDistance) {
          return {
            lat: point2.geometry.coordinates[1],
            lng: point2.geometry.coordinates[0],
          }
        } else {
          accumulatedDistance += segmentDistance
        }
      }
    }
  },

  buildVesselsGeojson({ routes, sliderPosition }) {
    // console.log("build vessel geojson", routes, sliderPosition)
    let vesselGeojson = routes.map((route) => {
      // const start = Date.now()

      // Look for vessel object in WPs
      let lastValidFeature = null
      for (let i = 0; i < route.points.geojson.features.length; i++) {
        if (route.points.geojson.features[i].properties.timestamp > sliderPosition.label) {
          lastValidFeature = route.points.geojson.features[i]
          break
        }
      }
      let vesselPosition = {
        ...route.points,
        geojson: {
          ...route.points.geojson,
          features: lastValidFeature ? [lastValidFeature] : route.points.geojson.features.slice(-1),
        },
      }
      // If route has < 2 WPs
      if (route.points.geojson.features.length < 2) {
        return { points: vesselPosition }
      }
      // Interpolation for time slider
      let routePoints = RouteAnalyser.setTimeIntervalTree(fromJS(route.points))
      vesselPosition = RouteAnalyser.setVesselInterpolatePointByTime(
        fromJS(vesselPosition),
        routePoints,
        sliderPosition.label,
        "rhumbline"
      )

      // set correct timestamp if sliderPosition before or after route
      vesselPosition = vesselPosition.toJS()
      if (
        vesselPosition.geojson.features[0]?.properties?.analyse?.interpolate_data?.intervaltree_match === "before_route"
      ) {
        const firstRouteFeatureTimestamp = route.points.geojson.features[0]?.properties?.timestamp
        vesselPosition.geojson.features[0].properties.analyse.interpolate_data.actual_timestamp =
          firstRouteFeatureTimestamp
      } else if (
        vesselPosition.geojson.features[0]?.properties?.analyse?.interpolate_data?.intervaltree_match === "after_route"
      ) {
        const lastRouteFeatureTimestamp =
          route.points.geojson.features[route.points.geojson.features.length - 1]?.properties?.timestamp
        vesselPosition.geojson.features[0].properties.analyse.interpolate_data.actual_timestamp =
          lastRouteFeatureTimestamp
      }
      vesselPosition = RouteAnalyser.setVesselBearing(fromJS(vesselPosition), routePoints, "rhumbline").toJS()
      // Check if albis_reports is null in the final vesselPosition
      if (vesselPosition.routeType === "real" && vesselPosition.geojson.features[0].properties.albis_reports === null) {
        // Loop through the route's features in reverse to find the last non-null albis_reports
        for (let i = route.points.geojson.features.length - 1; i >= 0; i--) {
          const feature = route.points.geojson.features[i]
          if (feature.properties.albis_reports !== null) {
            // Update the albis_reports in vesselPosition with the found non-null value
            vesselPosition.geojson.features[0].properties.albis_reports = feature.properties.albis_reports
            break // Exit the loop once the non-null albis_reports is found and assigned
          }
        }
        // } else if (
        //   vesselPosition.routeType === "plan" &&
        //   (vesselPosition.geojson.features[0].properties.eta_delta_waypoints === null ||
        //     vesselPosition.geojson.features[0].properties.eta_delta_waypoints === undefined)
        // ) {
        //   // Loop through the route's features in reverse to find the last non-null eta_dealta_waypoints
        //   for (let i = route.points.geojson.features.length - 1; i >= 0; i--) {
        //     const feature = route.points.geojson.features[i]
        //     if (feature.properties.eta_delta_waypoints !== null) {
        //       // Update the eta_delta_waypoints in vesselPosition with the found non-null value
        //       vesselPosition.geojson.features[0].properties.eta_delta_waypoints = feature.properties.eta_delta_waypoints
        //       break // Exit the loop once the non-null albis_reports is found and assigned
        //     }
        //   }
        //   console.log("vesselPosition plan", vesselPosition, route.points.geojson.features)
      } else {
        return { points: vesselPosition }
      }

      // const end = Date.now()
      // console.log(`interpolation ${route.points.vesselName}: ${end - start} ms for ${route.points.geojson.features.length} WPs (${(end - start)/route.points.geojson.features.length})`)

      return { points: vesselPosition }
    })
    return vesselGeojson
  },

  renderRotatedIcon({
    vesselPoints,
    markedVesselNames,
    setCenterLatLonMap,
    setMarkedVesselNames,
    setOpenDrawer,
    setOpenVesselList,
    map,
    handleMarkerClick,
  }) {
    map.createPane("vesselIconPane")
    map.getPane("vesselIconPane").style.zIndex = 1050
    // console.log("render rotated icon",routePoints.geojson.features[routePoints.geojson.features.length - 1].properties.analyse.rhumbline_bearing)
    const latlon = {
      lat: vesselPoints.geojson.features[vesselPoints.geojson.features.length - 1].geometry.coordinates[1],
      lng: vesselPoints.geojson.features[vesselPoints.geojson.features.length - 1].geometry.coordinates[0],
    }
    const marker = L.rotatedMarker(latlon, {
      icon: RouteHelper.selectVesselIcon(vesselPoints, markedVesselNames),
      interactive: true,
      rotationAngle:
        vesselPoints.geojson.features[vesselPoints.geojson.features.length - 1].properties.analyse.rhumbline_bearing /
        2,
      // JK 31.8. eigentlich 180+turf.bearing... https://github.com/bbecquet/Leaflet.PolylineDecorator/issues/94
      opacity: 1,
      fillOpacity: 1,
      pane: "vesselIconPane",
    })
    // Add the click event
    marker.on({
      click: () => {
        setCenterLatLonMap([latlon.lat, latlon.lng])
        handleMarkerClick()

        const realRoutesEndpoints = [
          {
            vesselName: vesselPoints.vesselName,
            routeType: vesselPoints.routeType,
            lonlat: [latlon.lng, latlon.lat],
          },
        ]
        if (setMarkedVesselNames) {
          setMarkedVesselNames(realRoutesEndpoints)
        }
        if (setOpenDrawer) {
          setOpenDrawer(true)
        }
        if (setOpenVesselList) {
          setOpenVesselList(true)
        }
      },
    })

    return marker
  },

  renderCircleIcon({
    latlon,
    vesselPoints,
    setCenterLatLonMap,
    setMarkedVesselNames,
    setOpenDrawer,
    setOpenVesselList,
    map,
    handleMarkerClick,
  }) {
    // console.log("render circle icon")
    // const marker = L.circleMarker(latlon, {
    //   radius: 8,
    //   opacity: 1,
    //   fillOpacity: 1,
    //   pane: "markerPane",
    //   color: "#1973c3",
    // })
    const marker = L.rotatedMarker(latlon, {
      icon: L.divIcon({
        className: "css-icon",
        html: ReactDOMServer.renderToString(
          <CircleIcon style={{ color: "blue", fontSize: 24, stroke: "white", strokeWidth: 1 }} />
        ),
        iconAnchor: [0.5 * 24, 0.5 * 24],
      }),
      interactive: true,
      rotationAngle: 0,
      opacity: 1,
      fillOpacity: 1,
      pane: "vesselIconPane",
    })
    // Add the click event
    marker.on({
      click: () => {
        setCenterLatLonMap([latlon.lat, latlon.lng])
        handleMarkerClick()

        const realRoutesEndpoints = [
          {
            vesselName: vesselPoints.vesselName,
            routeType: vesselPoints.routeType,
            lonlat: vesselPoints.geojson.features[vesselPoints.geojson.features.length - 1].geometry.coordinates,
          },
        ]
        if (setMarkedVesselNames) {
          setMarkedVesselNames(realRoutesEndpoints)
        }
        if (setOpenDrawer) {
          setOpenDrawer(true)
        }
        if (setOpenVesselList) {
          setOpenVesselList(true)
        }
      },
    })
    return marker
  },

  applyPopup({ route, feature, map, layer, vesselDisplayed, sliderPosition }) {
    let highlightCircle = null
    let timestamp = null
    let popupContent = null

    // check if WP,Vessel or LatestVesselPosition Popup
    if (vesselDisplayed === false) {
      timestamp = dayjs.utc(feature.properties.timestamp).format("ddd, D MMM YYYY, HH:mm:ss UTC")
      popupContent = `${route.vesselName} <br> WP ${feature.properties.number} ${
        feature.properties.name
      } <br><br> ${timestamp}  <br> ${Helper.ConvertDDToDM(feature.geometry.coordinates)}`
      //<br><br><button>click me</button>` // 'WP' auf wunsch von dennis+alina (2022-11-23)
    } else if (vesselDisplayed === true && sliderPosition) {
      // get interpolated vessel position of slider
      let latlonAlongRoute = null
      for (let i = 0; i < route.geojson.features.length; i++) {
        if (dayjs.utc(route.geojson.features[i].properties.timestamp).isSameOrAfter(sliderPosition.label)) {
          const interpolatedPosition = RouteHelper.interpolateVesselPosition(route, i - 1, i, sliderPosition)
          latlonAlongRoute = [interpolatedPosition.latlonAlongRoute.lng, interpolatedPosition.latlonAlongRoute.lat]
          break
        }
      }
      timestamp = dayjs.utc(sliderPosition.label).format("ddd, D MMM YYYY, HH:mm:ss UTC")
      popupContent = `${route.vesselName} <br> ${timestamp} <br> ${latlonAlongRoute}<br> ${Helper.ConvertDDToDM(
        latlonAlongRoute
      )} <br><ExpandMore/>`
      // <br><br><button>click me</button>` // 'WP' auf wunsch von dennis+alina (2022-11-23)
    } else {
      timestamp = dayjs.utc(feature.properties.timestamp).format("ddd, D MMM YYYY, HH:mm:ss UTC")
      popupContent = `${route.vesselName} <br> WP ${feature.properties.number} ${
        feature.properties.name
      } <br><br> ${timestamp} }<br> ${Helper.ConvertDDToDM(feature.geometry.coordinates)}`
      // <br><br><button>click me</button>` // 'WP' auf wunsch von dennis+alina (2022-11-23)
    }

    if (feature.properties && feature.properties.popupContent) {
      popupContent += feature.properties.popupContent
    }

    // VesselNamen zeichnen beim ersten rendern || sliderBewegung
    if (
      feature.properties.timestamp === route.geojson.features[route.geojson.features.length - 1].properties.timestamp ||
      (sliderPosition && dayjs.utc(feature.properties.timestamp).isSameOrAfter(sliderPosition.label))
    ) {
      // neuester real punkt
      // layer.bindTooltip("<div>" + "Vessel " + node.geojson.features.length + "</div>", {
      layer.bindTooltip("<div>" + route.vesselName + "</div>", {
        permanent: true,
        direction: "center",
        opacity: 0.6,
        offset: L.point(0, -30),
        className: "my_tooltip_labels_vessels_" + route.routeType, // siehe app.css und https://jsfiddle.net/maphew/gtdkxj8e/7/
      }) // https://stackoverflow.com/questions/34775308/leaflet-how-to-add-a-text-label-to-a-custom-marker-icon#34776015
    }

    layer.bindPopup(popupContent)

    // layer.on({
    //   mouseover: (e) => {
    //     console.log("mouseover")
    //     layer.openPopup(e.latlng)
    //     if (highlightCircle !== null) {
    //       e.target._map.removeLayer(highlightCircle)
    //     }
    //     // const pulsingIcon = L.icon.pulse({ iconSize: [20, 20], color: "blue" })
    //     // highlightCircle = L.marker(e.latlng, { icon: pulsingIcon }).addTo(e.target._map)
    //     highlightCircle = L.circleMarker(e.latlng, {
    //       radius: 18,
    //       weight: 4,
    //       color: "#fff",
    //       fillOpacity: 0,
    //     }).addTo(e.target._map)
    //   },
    //   mouseout: (e) => {
    //     console.log("mouseout")
    //     if (highlightCircle !== null) {
    //       e.target._map.removeLayer(highlightCircle)
    //       highlightCircle = null
    //     }
    //   },
    //   //   click: (e) => {
    //   //     console.log("popup click")
    //   //     if (highlightCircle !== null) {
    //   //       e.target._map.removeLayer(highlightCircle)
    //   //       highlightCircle = null
    //   //     }
    //   //   },
    // })

    // map.on({
    //   zoomend: (e) => {
    //     if (highlightCircle !== null) {
    //       map.removeLayer(highlightCircle)
    //       highlightCircle = null
    //     }
    //   },
    // })
  },
  applyWpPopup({ routePoints, feature, layer, pane }) {
    const timestamp = dayjs.utc(feature.properties.timestamp).format("ddd D MMM YYYY HH:mm UTC")
    const coordinates = Helper.ConvertDDToDM(feature.geometry.coordinates).join("&nbsp;&nbsp;&nbsp;")
    let popupContent = `<div style="width: auto; max-width: 400px;">` // auto will make it adjust to content, max-width will restrict it
    popupContent = `<b>${routePoints.vesselName}</b> <br>`
    if (feature.properties.albis_reports) {
      popupContent += `Daily Report<br>`
    }

    if (feature.properties.name) {
      popupContent += `WP: ${feature.properties.name}<br>`
    }

    popupContent += `${timestamp}  <br> ${coordinates}`

    // Define specific keys you want to include
    const allowedKeysR = [
      "speedOverGround_kn",
      "courseOverGround_deg",
      "plannedETA_local_ISO",
      "nextPort_LOCODE",
      // "mainEngineFuelConsumptionRate_t_per_d",
      "dailyMainEngineFuelConsumptionHFO_t",
    ]

    const allowedKeysS = [
      "mainEngineFuelConsumptionRate_t_per_d",
      "mainEngineFuelType",
      "speedOverGround_kn",
      "headingTrue_deg",
    ]

    const orderedKeys = {
      nextPort_LOCODE: { display: "Next Port", unit: "" },
      plannedETA_local_ISO: { display: "ETA", unit: "" },
      speedOverGround_kn: { display: "SOG", unit: "kn" },
      courseOverGround_deg: { display: "COG", unit: "°" },
      headingTrue_deg: { display: "Heading", unit: "°" },
      mainEngineFuelConsumptionRate_t_per_d: { display: "M/E FCR", unit: "t/d" },
      dailyMainEngineFuelConsumptionHFO_t: { display: "daily M/E FCR", unit: "t/d" },
      // mainEngineFuelType: { display: "M/E FT", unit: "" },
    }

    const addPropertiesToPopup = (propertiesObj, allowedKeys) => {
      popupContent += `<br><br><details style=""><summary><b>Details</b></summary>`
      popupContent += `<table >`

      for (let [key, info] of Object.entries(orderedKeys)) {
        if (allowedKeys.includes(key)) {
          let value = propertiesObj[key]

          if (value !== undefined) {
            if (key === "plannedETA_local_ISO" && value !== null) {
              value = dayjs.utc(value).format("ddd D MMM YYYY HH:mm UTC")
            } else if (key === "speedOverGround_kn" && value !== null) {
              value = parseFloat(value).toFixed(1) + (info.unit ? ` ${info.unit}` : "")
            } else if (key === "courseOverGround_deg" && value !== null) {
              value = Math.round(value) + (info.unit ? ` ${info.unit}` : "")
            } else if (key === "headingTrue_deg" && value !== null) {
              value = Math.round(value) + (info.unit ? ` ${info.unit}` : "")
            } else if (key === "mainEngineFuelConsumptionRate_t_per_d" && value !== null) {
              value = parseFloat(value).toFixed(1) + (info.unit ? ` ${info.unit}` : "")
            } else if (value === null) {
              value = "Not available"
            } else if (info.unit) {
              value = `${value} ${info.unit}`
            }

            popupContent += `<tr>
                                <td style="padding: 2px; "><b>${info.display}:</b></td>
                                <td style="padding: 2px;">${value}</td>
                            </tr>`
          }
        }
      }
      popupContent += `</table></details>`
    }

    if (feature.properties.albis_sensors) {
      addPropertiesToPopup(feature.properties.albis_sensors, allowedKeysS)
    } else if (feature.properties.albis_reports) {
      addPropertiesToPopup(feature.properties.albis_reports, allowedKeysR)
    }

    if (feature.properties && feature.properties.popupContent) {
      popupContent += feature.properties.popupContent
    }
    popupContent += `</div>`

    // Display the popup on hover
    layer.on("click", function () {
      layer.bindPopup(popupContent, { autoClose: false, pane: pane }).openPopup()
    })
  },

  applyVesselPopup({ vesselPoints, feature, layer }) {
    // permanent vessel name
    layer.bindTooltip("<div>" + vesselPoints.vesselName + "</div>", {
      permanent: true,
      direction: "center",
      opacity: 1,
      offset: L.point(0, -30),
      className: "my_tooltip_labels_vessels_" + vesselPoints.routeType, // siehe app.css und https://jsfiddle.net/maphew/gtdkxj8e/7/
    })

    // vessel popup
    let timestamp = null
    let popupContent = null
    // check if analyse is available
    if (feature.properties.analyse?.interpolate_data?.actual_timestamp) {
      timestamp = dayjs
        .utc(feature.properties.analyse.interpolate_data.actual_timestamp)
        .format("ddd D MMM YYYY HH:mm UTC")
    } else {
      timestamp = dayjs.utc(feature.properties.timestamp).format("ddd D MMM YYYY HH:mm UTC")
    }
    const coordinates = Helper.ConvertDDToDM(feature.geometry.coordinates).join(
      "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
    )

    popupContent = `<b>${vesselPoints.vesselName}</b><br> ${timestamp} <br> ${coordinates}`

    // Different SOG and COG for different WP types
    // for albis_sensors
    if (vesselPoints.routeType === "real" && feature.properties.albis_sensors) {
      popupContent += `<br> SOG: ${parseFloat(feature.properties.analyse?.speed_by_sog[0]).toFixed(
        1
      )} kn&nbsp;&nbsp;&nbsp;COG: ${parseFloat(feature.properties.heading).toFixed(0)} °`
    }
    //for albis_reports and interpolated WP
    else if (
      vesselPoints.routeType === "real" &&
      (feature.properties.albis_reports || feature.properties.legtype === "time_interpolated_point")
    ) {
      popupContent += `<br> SOG: 
      kn&nbsp;&nbsp;&nbsp;COG: ${parseFloat(feature.properties.analyse?.rhumbline_bearing).toFixed(0)} °`
    }

    if (feature.properties && feature.properties.popupContent) {
      popupContent += feature.properties.popupContent
    }

    // Display the popup on hover
    layer.on("mouseover", function () {
      layer.bindPopup(popupContent).openPopup()
    })

    // Remove the popup when mouse is no longer hovering over the feature
    layer.on("mouseout", function () {
      layer.closePopup()
    })
  },

  createLineStringRoute(route, sliderTicks, sliderPosition) {
    // console.log("route", route)
    const features = route.geojson.features
    let concatLineStrings = []
    let allLineStrings = { coords: [], style: [] }
    let featureCollection = null
    let latlonAlongRoute = null
    let nextCoordinates = null

    if (!features[0] || !sliderTicks) return
    // add first element to lineString if first WP is before first sliderTick
    if (dayjs.utc(features[0].properties.timestamp).isBefore(sliderTicks[0].label)) {
      // console.log("before", route.vesselName, route.routeType)
      let point1 = turf.point(features[0].geometry.coordinates)
      let point2

      for (let j = 1; j < features.length; j++) {
        if (checkDistance(point1, turf.point(features[j].geometry.coordinates))) {
          point2 = turf.point(features[j].geometry.coordinates)
          const { speed, timeDiffInterp } = RouteHelper.calculateDynamics(
            point1,
            point2,
            features[0].properties.timestamp,
            features[j].properties.timestamp,
            sliderTicks[0].label
          )

          let latlonAlongRoute = RouteHelper.determineLegTypeAndCallFunction(
            features[0].properties.legtype,
            point1,
            speed,
            timeDiffInterp,
            point2
          )

          //if latlonAlongRoute in gap and reported route
          if (
            latlonAlongRoute &&
            route.routeType === "real" &&
            checkGapBetweenTimestamps(features[j].properties.timestamp, features[0].properties.timestamp)
          ) {
            allLineStrings.coords.push([[latlonAlongRoute.lng, latlonAlongRoute.lat], point2.geometry.coordinates])
            allLineStrings.style.push("dashed")
          }
          // if latlonAlongRoute not in gap
          else if (latlonAlongRoute) {
            if (features[0].properties.legtype === "rhumbline") {
              concatLineStrings.push([latlonAlongRoute.lng, latlonAlongRoute.lat])
            } else {
              const lineRoute = turf.greatCircle(turf.point([latlonAlongRoute.lng, latlonAlongRoute.lat]), features[j])
              // concatLineStrings.push([latlonAlongRoute.lng, latlonAlongRoute.lat])
              let lineStringPart = []
              lineStringPart = lineStringPart.concat(lineRoute.geometry.coordinates)
              concatLineStrings = concatLineStrings.concat(lineStringPart)
            }
          }
          break
        }
      }
    }
    //  add first element to lineString if first WP is after first sliderTick
    else if (dayjs.utc(features[0].properties.timestamp).isSameOrAfter(sliderTicks[0].label)) {
      concatLineStrings.push(features[0].geometry.coordinates)
    }

    // loop through all WPs, build lineStrings and construct final FeatureCollection
    for (let i = 1; i < features.length - 1; i++) {
      const currentFeature = features[i]
      const currentCoordinates = currentFeature.geometry.coordinates
      let nextFeature = null
      let greatCircleLine = null
      let lineStringPart = []

      // get nextFeature with defined coordinates
      if (hasValidCoordinates(features[i + 1])) {
        nextFeature = features[i + 1]
        nextCoordinates = processNextFeature(nextFeature, route, i, i + 1, sliderPosition)
      } else {
        const result = getNextFeatureWithCoordinates(features, i)
        if (result) {
          nextFeature = result.nextFeature
          nextCoordinates = processNextFeature(nextFeature, route, i, result.index, sliderPosition)
        }
      }

      // for real routes check if time difference between currentFeature and nextFeature is larger then 3 hours
      if (
        route.routeType === "real" &&
        checkGapBetweenTimestamps(nextFeature.properties.timestamp, currentFeature.properties.timestamp)
      ) {
        // push previous concatLineStrings into allLineStrings and create new concateLineStrings
        allLineStrings.coords.push(concatLineStrings)
        allLineStrings.style.push("line") // hier muss noch geguckt werden, ob gap ab erstem Wp --> dann als dashed
        concatLineStrings = []
        // create concatLineStrings from currentCoordinates and nextCoordinates that have >3 hour timedifference, including dateline split
        const startPoint = turf.point(currentCoordinates)
        const endPoint = turf.point(nextCoordinates)
        if (!turf.booleanEqual(startPoint, endPoint)) {
          // turf.greatCircle with just 2 npoints gives straight line (rhumbline)
          greatCircleLine = new turf.greatCircle(startPoint, endPoint, { npoints: 2 })
          // in case of MultiLineString (dateline crossing)
          if (greatCircleLine.geometry.type === "MultiLineString") {
            // add coordinates[0] to previous linestring, push it into allLineStrings and than start new linestring with coordinates[1]
            lineStringPart = handleMultiLineString(lineStringPart, greatCircleLine)
          } else {
            // if normal linestring (no dateline crossing)
            lineStringPart = lineStringPart.concat(greatCircleLine.geometry.coordinates)
          }
        }
        // when startPoint === endPoint then skip linestringPart and continue loop
        else {
          continue
        }
        concatLineStrings = concatLineStrings.concat(lineStringPart)
        // push concatLineStrings with time difference >3 hours into allLineStrings and create new concateLineStrings
        allLineStrings.coords.push(concatLineStrings)
        allLineStrings.style.push("dashed")
        concatLineStrings = []
        lineStringPart = []
        continue
      }

      // get coordinates of features and calculate greatCircleLine
      const startPoint = turf.point(currentCoordinates)
      const endPoint = turf.point(nextCoordinates)
      if (!turf.booleanEqual(startPoint, endPoint)) {
        if (currentFeature.properties.timestamp === "2023-07-16T00:10:01+00:00") {
          // console.log(
          //   currentFeature,
          //   nextFeature,
          //   turf.greatCircle(startPoint, endPoint, { npoints: 100, offset: 360 })
          // )
        }

        // Check if the route type is rhumbline "1"
        if (currentFeature.properties.legtype === "rhumbline") {
          // console.log("current feature", i, currentFeature)
          // greatCircleLine = new turf.greatCircle(startPoint, endPoint, { npoints: 2 }) // JK 13.7. npoints:2 is not making MultiLineString on dateline crossing...

          // turf.greatCircle with just 2 npoints gives straight line (rhumbline)
          // JK 13.7. npoints:2 is not making MultiLineString on dateline crossing so analyse.antimeridian is used
          if (
            currentFeature.properties.analyse.antimeridian.is_antimeridian &&
            currentFeature.properties.analyse.antimeridian.point1.length !== 0
          ) {
            greatCircleLine = turf.multiLineString([
              [startPoint.geometry.coordinates, currentFeature.properties.analyse.antimeridian.point1],
              [currentFeature.properties.analyse.antimeridian.point2, endPoint.geometry.coordinates],
            ])
          } else {
            greatCircleLine = new turf.greatCircle(startPoint, endPoint, { npoints: 2 })
          }
        }
        // routeType is other then rhumbline
        else {
          // create greatCricle with 100 points
          greatCircleLine = new turf.greatCircle(startPoint, endPoint, { npoints: 100 })
        }

        // in case of MultiLineString (dateline crossing)
        if (greatCircleLine.geometry.type === "MultiLineString") {
          // console.log(greatCircleLine)
          // make sure MultiLineString part is on same site of dateline
          if (
            Math.sign(greatCircleLine.geometry.coordinates[1][0][0]) !==
            Math.sign(greatCircleLine.geometry.coordinates[1][1][0])
          ) {
            lineStringPart = lineStringPart.concat([
              greatCircleLine.geometry.coordinates[0][0],
              greatCircleLine.geometry.coordinates[1][1],
            ])
            concatLineStrings = concatLineStrings.concat(lineStringPart)
            allLineStrings.coords.push(concatLineStrings)
            allLineStrings.style.push("line")
            lineStringPart = []
            concatLineStrings = []
          }
          // add coordinates[0] to previous linestring, push it into allLineStrings and than start new linestring with coordinates[1]
          else {
            lineStringPart = lineStringPart.concat(greatCircleLine.geometry.coordinates[0])
            concatLineStrings = concatLineStrings.concat(lineStringPart)
            allLineStrings.coords.push(concatLineStrings)
            allLineStrings.style.push("line")
            lineStringPart = []
            concatLineStrings = []

            lineStringPart = lineStringPart.concat(greatCircleLine.geometry.coordinates[1])
          }
        }
        // normal Linestring (no dateline crossing)
        else {
          lineStringPart = lineStringPart.concat(greatCircleLine.geometry.coordinates)
        }
      }
      // startPoint === endPoint
      else {
        // console.log("startPoint === endPoint",startPoint.geometry.coordinates,endPoint.geometry.coordinates)
      }
      concatLineStrings = concatLineStrings.concat(lineStringPart)
      // concatLineStrings.pop() // wegpunktdopplung wegnehmen um linestring zu generieren führt zu problemen wenn time differenz >3
    }

    // add concatLineStrings to allLineStrings
    if (concatLineStrings.length !== 0) {
      allLineStrings.coords.push(concatLineStrings)
      allLineStrings.style.push("line")
    }
    // console.log("allLineStrings", route.vesselName, allLineStrings)
    // Create a FeatureCollection from allLineStrings and lineStringFeatures
    const lineStringFeatures = allLineStrings.coords.map((coords, index) => {
      let lineString
      if (coords.length > 1) {
        lineString = turf.lineString(coords)
        const style = allLineStrings.style[index]
        lineString.properties.lineStyle = style
        lineString.properties.routeType = route.routeType
      }
      return lineString
    })
    // console.log("linestring", lineStringFeatures)
    featureCollection = turf.featureCollection(lineStringFeatures)
    // Add additional properties to the FeatureCollection
    const finalCollection = {
      geojson: featureCollection,
      routeType: route.routeType,
      vesselName: route.vesselName,
    }
    // console.log("finalCollection", finalCollection.geojson)
    return finalCollection

    function handleMultiLineString(lineStringPart, greatCircleLine) {
      lineStringPart = lineStringPart.concat(greatCircleLine.geometry.coordinates[0])
      concatLineStrings = concatLineStrings.concat(lineStringPart)
      allLineStrings.coords.push(concatLineStrings)
      allLineStrings.style.push("dashed")
      lineStringPart = []
      concatLineStrings = []
      lineStringPart = lineStringPart.concat(greatCircleLine.geometry.coordinates[1])
      return lineStringPart
    }

    function checkDistance(point1, point2) {
      return turf.distance(point1, point2) >= 0.017
    }

    function checkGapBetweenTimestamps(timestamp1, timestamp2) {
      return dayjs.utc(timestamp1) - dayjs.utc(timestamp2) > 3 * 60 * 60 * 1000
    }

    function hasValidCoordinates(feature) {
      return feature.geometry.coordinates && feature.geometry.coordinates[0] && feature.geometry.coordinates[1]
    }

    function getNextFeatureWithCoordinates(features, startIndex) {
      for (let j = startIndex + 2; j < features.length; j++) {
        if (hasValidCoordinates(features[j])) {
          return { nextFeature: features[j], index: j }
        }
      }
      return null
    }

    function isNextFeatureAfterLastTick(nextFeature, sliderTicks) {
      return dayjs.utc(nextFeature.properties.timestamp).isAfter(sliderTicks[sliderTicks.length - 1].label)
    }

    function processNextFeature(nextFeature, route, i, j, sliderPosition) {
      let nextCoordinates = nextFeature.geometry.coordinates
      if (isNextFeatureAfterLastTick(nextFeature, sliderTicks)) {
        const latlonAlongPlanRoute = RouteHelper.interpolateVesselPosition(
          route,
          i,
          j,
          sliderTicks[sliderTicks.length - 1]
        ).latlonAlongRoute
        nextCoordinates = [latlonAlongPlanRoute.lng, latlonAlongPlanRoute.lat]
      }
      return nextCoordinates
    }
  },
  calculateDynamics(point1, point2, timestamp1, timestamp2, label) {
    const distance = turf.distance(point1, point2, { units: "kilometers" })
    const timeDiffInterp = Math.abs((dayjs.utc(timestamp1) - dayjs.utc(label)) / 1000)
    const timeDiffP2P1 = (dayjs.utc(timestamp2) - dayjs.utc(timestamp1)) / 1000
    const speed = distance / timeDiffP2P1

    return { speed, timeDiffInterp }
  },
  determineLegTypeAndCallFunction(legtype, point1, speed, timeDiffInterp, point2) {
    let latlonAlongRoute

    if (legtype === "rhumbline") {
      latlonAlongRoute = RouteHelper.getRhumblinePoint(point1, speed, timeDiffInterp, point2)
    } else {
      latlonAlongRoute = RouteHelper.getGreatcirclePoint(point1, point2, speed, timeDiffInterp)
    }

    return latlonAlongRoute
  },

  getLatestReportedWaypoint(route) {
    //no waypoint
    if (route.geojson.features.length === 0) {
      return null
    }
    return route.geojson.features[route.geojson.features.length - 1]
  },

  markBoundingboxPoints(routePoints, mapBounds) {
    routePoints = fromJS(routePoints)
    const BBOX_MAP_TEST = turf.polygon([mapBounds])

    const bboxName = "complete_route"
    routePoints = RouteAnalyser.setBoundingBoxPolygon(routePoints, bboxName, routePoints) // "is_gap_distance"
    routePoints = RouteAnalyser.markWithinBbox(
      routePoints,
      routePoints.getIn(["geojson", "analyse", "bbox_polygon", bboxName]),
      BBOX_MAP_TEST,
      bboxName
    )
    routePoints = RouteAnalyser.markOverlapBbox(
      routePoints,
      routePoints.getIn(["geojson", "analyse", "bbox_polygon", bboxName]),
      BBOX_MAP_TEST,
      bboxName
    )
    // routePoints=(routePoints.getIn(['geojson','analyse','is_'+bboxName+'_overlap_map']))?
    routePoints = RouteAnalyser.markPointsInBbox(routePoints, BBOX_MAP_TEST, "is_in_map") // TODO mit/ohne timerange bbox!!!

    // Filter features for is_in_map and is_simplify
    routePoints = routePoints.toJS()
    routePoints.geojson.features = routePoints.geojson.features.filter((feature) => {
      // First condition: feature must be in map
      if (!feature.properties.analyse.is_in_map) {
        return false
      }
      // Second condition: feature is either simplified or is a time interpolated point
      return feature.properties.analyse.is_simplify || feature.properties.legtype === "time_interpolated_point"
    })
    return routePoints
  },

  markBoundingboxLine(routeLine, mapBounds) {
    let routeLines = fromJS(routeLine)
    const BBOX_MAP_TEST = turf.polygon([mapBounds])
    const bboxName = "complete_route"
    routeLines = RouteAnalyser.setBoundingBoxPolygon(routeLines, bboxName, routeLines) // "is_gap_distance"
    routeLines = RouteAnalyser.markWithinBbox(
      routeLines,
      routeLines.getIn(["geojson", "analyse", "bbox_polygon", bboxName]),
      BBOX_MAP_TEST,
      bboxName
    )
    routeLines = RouteAnalyser.markOverlapBbox(
      routeLines,
      routeLines.getIn(["geojson", "analyse", "bbox_polygon", bboxName]),
      BBOX_MAP_TEST,
      bboxName
    )
    routeLines = RouteAnalyser.markLinesInBbox(routeLines, BBOX_MAP_TEST, "is_in_map") // TODO mit/ohne timerange bbox!!!
    // console.log(routeLine.toJS());
    // routeLine = routeLine.geojson.toJS()

    // // simplify functions:
    // routeLines = RouteAnalyser.setRouteLinestring(routePoints) //  -> (local between neighbor waypoints) -> postgres function with shifting/sliding window
    // routeLines = RouteAnalyser.setSimplifyLinestringDouglasPeucker(routePoints, 0.01)
    // // routeLines = RouteAnalyser.setSimplifyPointset(routePoints)
    // // routeLines = RouteAnalyser.markSimplifyPoints(routePoints)
    return routeLines.geojson
  },

  filterViewportBoundingbox({ map, features: waypoints, zoomLevel, routeType }) {
    if (routeType === "real") {
      // when only one feature available return this
      if (
        waypoints.length === 1 &&
        map.getBounds().contains([waypoints[0].geometry.coordinates[1], waypoints[0].geometry.coordinates[0]])
      ) {
        return waypoints
      }
      for (let i = 0; i < waypoints.length - 1; i++) {
        let filteredWaypoints = null
        if (map.getBounds().contains([waypoints[i].geometry.coordinates[1], waypoints[i].geometry.coordinates[0]])) {
          filteredWaypoints = RouteHelper.filterWps(waypoints, zoomLevel)
          // console.log("filteredViewport", filteredWaypoints)
          return filteredWaypoints
        } else if (filteredWaypoints === null) {
          // filteredWaypoints = waypoints
          // console.log("no points in filterViewportBoundingbox")
          // return filteredWaypoints
        }
      }
    } else if (routeType === "plan" || routeType === "suggest") {
      // hier muss eventuell noch eine andere Lösung her, falls viele Plan Routen/Punkte vorhanden
      const filteredWaypoints = waypoints
      // console.log("filterViewportBoundingbox plan", filteredFeatures)
      return filteredWaypoints
    }
    const result = null
    return result
  },

  filterWps(features, zoomLevel) {
    return features.filter((feature, j) => {
      // check if both coordinates are defined
      if (feature.geometry.coordinates && feature.geometry.coordinates[0] && feature.geometry.coordinates[1]) {
        // Include the first and last feature
        if (j === 1 || j === features.length - 1) {
          return true
        } else {
          return j % zoomWpNumberThreshold[zoomLevel] === 0
        }
      }
    })
  },

  createKey(index, zoomLevel, sliderPosition, markedVesselNames, dragged, resized) {
    const key =
      index +
      " " +
      zoomLevel +
      " " +
      sliderPosition +
      " " +
      JSON.stringify(markedVesselNames) +
      "" +
      dragged +
      "" +
      resized
    // console.log("key=", key)
    return key
  },

  getCurrentPlanPosition(route) {
    let point1 = null
    let point2 = null
    for (let i = 1; i < route.geojson.features.length; i++) {
      if (dayjs.utc(route.geojson.features[i].properties.timestamp).isSameOrAfter(dayjs.utc())) {
        point1 = turf.point(route.geojson.features[i - 1].geometry.coordinates)
        point2 = turf.point(route.geojson.features[i].geometry.coordinates)
        const distance = turf.distance(point1, point2, { units: "kilometers" })
        const timeDiffP2P1 =
          (dayjs.utc(route.geojson.features[i].properties.timestamp) -
            dayjs.utc(route.geojson.features[i - 1].properties.timestamp)) /
          1000
        const timeDiffInterp =
          Math.abs(dayjs.utc() - dayjs.utc(route.geojson.features[i - 1].properties.timestamp)) / 1000
        const speed = distance / timeDiffP2P1
        if (route.geojson.features[i - 1].properties.legtype === "rhumbline") {
          const latlonAlongRoute = RouteHelper.getRhumblinePoint(point1, speed, timeDiffInterp, point2)
          // console.log("currentPosition rhumbline", latlonAlongRoute)
          return latlonAlongRoute
        } else {
          const latlonAlongRoute = RouteHelper.getGreatcirclePoint(point1, point2, speed, timeDiffInterp)
          // console.log("currentPosition great circle", latlonAlongRoute)
          return latlonAlongRoute
        }
      }
    }
  },
}

export default RouteHelper
