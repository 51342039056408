import React, { useState, useEffect, useCallback, useRef } from "react"
import { useMap, ImageOverlay } from "react-leaflet"
import L, { CRS } from "leaflet"
import { WMSTileLayerWithHeader } from "./WMSTileLayerWithHeader"
import ImageOverlayWithHeader from "./ImageOverlayWithHeader"
import WMSLegend from "./WMSLegend"
import CoastlineLayer from "./CoastlineLayer"
import { useAccessContext } from "../../modules/main/AccessContext"

const MAX_LATITUDE = 85.06

function checkObjectKeys(obj) {
  if (Object.keys(obj).length === 0) {
    return false
  }
  for (const model in obj) {
    const parameters = obj[model]
    if (typeof parameters === "object" && parameters !== null) {
      for (const parameter in parameters) {
        const layerStyles = parameters[parameter]
        if (typeof layerStyles === "object" && layerStyles !== null) {
          for (const layer in layerStyles) {
            if (Array.isArray(layerStyles[layer]) && layerStyles[layer].length > 0) {
              return true
            }
          }
        }
      }
    }
  }
  return false
}

function debounce(func, wait) {
  let timeout
  return function (...args) {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), wait)
  }
}

function getLegendGraphicUrl(baseUrl, layerName, styleName, format = "image/png", width = 20, height = 1) {
  const url = new URL(baseUrl)
  url.searchParams.append("REQUEST", "GetLegendGraphic")
  url.searchParams.append("FORMAT", format)
  url.searchParams.append("LAYER", layerName)
  url.searchParams.append("STYLE", styleName)
  url.searchParams.append("WIDTH", width)
  url.searchParams.append("HEIGHT", height)
  return url.toString()
}

function getCoastlineUrl(GEOSERVER_URL, mapSize, adjustedHeight, mercatorMapBounds) {
  const url = new URL(`${GEOSERVER_URL}ne/wms?service=WMS`)
  url.searchParams.append("REQUEST", "GetMap")
  url.searchParams.append("FORMAT", "image/png")
  url.searchParams.append("TRANSPARENT", "True")
  url.searchParams.append("LAYERS", "land_sea_chart_3857")
  url.searchParams.append("STYLES", "coastline")
  url.searchParams.append("WIDTH", mapSize.x)
  url.searchParams.append("HEIGHT", adjustedHeight)
  url.searchParams.append("CRS", "EPSG:3857")
  url.searchParams.append("BBOX", mercatorMapBounds.join(","))
  return url.toString()
}

function toMercator(coords) {
  const R = 6378137 // Earth's radius in meters
  const [lng, lat] = coords
  const x = (R * lng * Math.PI) / 180
  const y = R * Math.log(Math.tan(Math.PI / 4 + (lat * Math.PI) / 360))
  return [x, y]
}

function toWgs84(coords) {
  const R = 6378137 // Earth's radius in meters
  const [x, y] = coords
  const lng = ((x / R) * 180) / Math.PI
  const lat = ((Math.atan(Math.exp(y / R)) - Math.PI / 4) * 360) / Math.PI
  return [lng, lat]
}

function getCurrentMapBounds(bounds) {
  // const bounds = map.getBounds()
  const west = bounds.getWest()
  const east = bounds.getEast()
  const south = bounds.getSouth()
  const north = bounds.getNorth()

  // console.log("west", west, "\neast", east, "\nsouth", south, "\nnorth", north)

  const clampedSouth = Math.max(south, -MAX_LATITUDE)
  const clampedNorth = Math.min(north, MAX_LATITUDE)

  const westSouth = toMercator([west, clampedSouth])
  const eastNorth = toMercator([east, clampedNorth])
  // console.log("\nwestSouth mercator", westSouth, "\neastNorth mercator", eastNorth)
  // console.log("\nwestSouth wgs84", toWgs84(westSouth), "\neastNorth wgs84", toWgs84(eastNorth))

  const westC = Math.min(westSouth[0], eastNorth[0])
  const eastC = Math.max(westSouth[0], eastNorth[0])
  const southC = Math.min(westSouth[1], eastNorth[1])
  const northC = Math.max(westSouth[1], eastNorth[1])

  return [westC, southC, eastC, northC]
}

function getAdjustedHeight(bounds, clampedBounds, mapSize) {
  // const bounds = map.getBounds()
  const westSouth = toMercator([bounds.getWest(), bounds.getSouth()])
  const eastNorth = toMercator([bounds.getEast(), bounds.getNorth()])
  const originalHeight = eastNorth[1] - westSouth[1]

  const clampedHeight = clampedBounds[3] - clampedBounds[1]

  const heightRatio = clampedHeight / originalHeight
  // const mapSize = map.getSize()
  const adjustedHeight = mapSize.y * heightRatio

  return Math.round(adjustedHeight)
}

export default function GeoServerController({
  GEOSERVER_URL,
  sliderPosition,
  checkedWMSLayers,
  drawerWidth,
  openDrawer,
  resized,
}) {
  const accessContext = useAccessContext()
  const accessToken = accessContext.token
  const renderedLayers = useRef({})
  const [layers, setLayers] = useState([])
  const [legends, setLegends] = useState([])
  const map = useMap()
  const mapSize = map.getSize()
  const formattedTimestamp = sliderPosition.label.replace(/\+00:00$/, ".000Z")

  const updateLayers = useCallback(() => {
    const latlngMapBounds = map.getBounds()
    const mercatorMapBounds = getCurrentMapBounds(latlngMapBounds)
    const clampedBounds = getCurrentMapBounds(latlngMapBounds)
    const adjustedHeight = getAdjustedHeight(latlngMapBounds, clampedBounds, mapSize)

    const currentLayerKeys = new Set()
    const newLegends = []
    const newLayers = []

    Object.entries(checkedWMSLayers).forEach(([model, parameters]) => {
      Object.entries(parameters).forEach(([parameter, layerStyles]) => {
        Object.entries(layerStyles).forEach(([layer, styles]) => {
          styles.forEach((style) => {
            // console.log(style)
            const modelUrl = `${GEOSERVER_URL}${model}/wms`

            let zIndex, opacity, legendUrl, coastlineUrl
            if (style.includes("_PColor")) {
              zIndex = 1001
              opacity = 1
              legendUrl = getLegendGraphicUrl(
                modelUrl,
                `${parameter}_RColor`,
                style.replace("_PColor", "_RColor_Inter")
              )
              coastlineUrl = getCoastlineUrl(GEOSERVER_URL, mapSize, adjustedHeight, mercatorMapBounds)
            } else if (style.includes("_RColor")) {
              zIndex = 1001
              opacity = 1
              legendUrl = getLegendGraphicUrl(modelUrl, layer, style.replace("_RColor", "_RColor_Inter"))
              coastlineUrl = getCoastlineUrl(GEOSERVER_URL, mapSize, adjustedHeight, mercatorMapBounds)
            } else if (style.includes("_Contour")) {
              zIndex = 1002
              opacity = 1
            } else {
              zIndex = 1003
              opacity = 1
            }

            const wmsKey = `${model}_${layer}_${style}_${formattedTimestamp}_${mercatorMapBounds}`
            // console.log(wmsKey)
            currentLayerKeys.add(wmsKey)
            if (!renderedLayers.current[wmsKey]) {
              const imageUrl = `${modelUrl}?service=WMS&request=GetMap&layers=${layer}&styles=${style}&format=image/png&transparent=true&version=1.3.0&width=${
                mapSize.x
              }&height=${adjustedHeight}&crs=EPSG:3857&bbox=${mercatorMapBounds.join(",")}&time=${formattedTimestamp}`
              renderedLayers.current[wmsKey] = (
                <div className={`layer_${wmsKey}`} key={`layer_${wmsKey}`}>
                  <ImageOverlayWithHeader
                    key={`layer_${wmsKey}`}
                    url={imageUrl}
                    bounds={
                      new L.LatLngBounds(
                        [Math.max(latlngMapBounds.getSouth(), -MAX_LATITUDE), latlngMapBounds.getWest()],
                        [Math.min(latlngMapBounds.getNorth(), MAX_LATITUDE), latlngMapBounds.getEast()]
                      )
                    }
                    opacity={opacity}
                    zIndex={zIndex}
                    headers={{ Authorization: "Bearer " + accessToken }}
                    // onLoad={console.log("fetch weather layer", wmsKey)}
                  />
                </div>
              )
            }

            newLayers.push(renderedLayers.current[wmsKey])
            if (style.includes("_RColor") || style.includes("_PColor")) {
              const coastlineKey = `"coastline_layer"_${mercatorMapBounds}`
              currentLayerKeys.add(coastlineKey)
              renderedLayers.current[coastlineKey] = (
                <div key={`layer_${coastlineKey}`}>
                  <CoastlineLayer
                    coastlineUrl={coastlineUrl}
                    coastlineKey={coastlineKey}
                    latlngMapBounds={latlngMapBounds}
                    MAX_LATITUDE={MAX_LATITUDE}
                  />
                </div>
              )
              newLayers.push(renderedLayers.current[coastlineKey])
              const legendKey = `legend_${layer}_${style}`
              newLegends.push(
                <div key={legendKey}>
                  <WMSLegend
                    key={legendKey}
                    legendUrl={legendUrl}
                    drawerWidth={drawerWidth}
                    openDrawer={openDrawer}
                    layerName={`${model}:${parameter}`}
                    styleName={style}
                  />
                </div>
              )
            }
          })
        })
      })
    })
    setLayers(newLayers)
    setLegends(newLegends)
  }, [GEOSERVER_URL, checkedWMSLayers, sliderPosition, accessToken, drawerWidth, openDrawer, resized])

  useEffect(() => {
    updateLayers()
  }, [sliderPosition, checkedWMSLayers, updateLayers, resized])

  useEffect(() => {
    const onMoveEnd = () => {
      updateLayers()
    }
    map.on("moveend", onMoveEnd)
    return () => {
      map.off("moveend", onMoveEnd)
    }
  }, [map, updateLayers])

  return (
    <>
      {layers}
      <div className="legends-container">{legends}</div>
    </>
  )
}
