import { useState, useEffect } from "react"
import PostGraphileApi from "../utils/PostGraphileApi"
import { useAccessContext } from "../modules/main/AccessContext"

export const useFetchWaypointsGeojson = (
  fromPicker,
  toPicker,
  routeType,
  FETCH_URL,
  access,
  hasDateChanged,
  setHasDateChanged
) => {
  // console.log("useFetchWaypointsGeojson hook", routeType, "\n", fromPicker.format(), "\n", toPicker.format())
  const accessContext = useAccessContext()
  const accessToken = accessContext.token

  const [tritonWaypointsGeojson, setTritonWaypointsGeojson] = useState(null)
  const [loadingStates, setLoadingStates] = useState({
    real: false,
    planned: false,
    suggested: false,
  })

  useEffect(() => {
    if (!hasDateChanged) {
      setLoadingStates((prevStates) => ({ ...prevStates, [routeType]: true }))
      // console.log("useFetchWaypointsGeojson hook", hasDateChanged)

      const graphqlQuery = `query MyQuery {
        tritonWaypointsGeojson(
          vesselAccountId: ${access.payload.accountId}
          startDate: "${fromPicker.toISOString()}"
          endDate: "${toPicker.toISOString()}"
          selectedRouteType:"${routeType}"
        ) {
          nodes {
            geojson
            vesselName
            routeType
            routeId
          }
        }
      }`
      // console.log(graphqlQuery)
      const startTime = performance.now() // Start time before the fetch

      PostGraphileApi.fetchRequest(
        FETCH_URL,
        PostGraphileApi.httpRequestData(graphqlQuery, accessToken),
        ".loadGraphql() fehler_1="
      )
        .then(({ data }) => {
          const endTime = performance.now() // End time after the fetch
          console.log(`fetchRequest ${routeType} took ${endTime - startTime} ms`)
          setTritonWaypointsGeojson(data)
          setLoadingStates((prevStates) => ({ ...prevStates, [routeType]: false }))
          setHasDateChanged(false)
        })
        .catch((error) => {
          console.error("An error occurred:", error)
          setLoadingStates((prevStates) => ({ ...prevStates, [routeType]: false }))
        })
    }
  }, [hasDateChanged])

  return { tritonWaypointsGeojson, loadingState: loadingStates[routeType] }
}
