import { useEffect, useState } from "react"
import dayjs from "dayjs"
import {
  FormControlLabel,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Switch,
  FormControl,
  InputAdornment,
  InputLabel,
  IconButton,
  OutlinedInput,
  Collapse,
  TextField,
  Paper,
} from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import { LoadingButton } from "@mui/lab"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import CloseIcon from "@mui/icons-material/Close"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"

import EtaCalculatorFlask from "../voyagePlanner/EtaCalculatorFlask"
import Pathfinder from "../voyagePlanner/Pathfinder"
import { useAccessContext } from "../../modules/main/AccessContext"

import * as turf from "@turf/turf"

function formatCoord(value) {
  return Math.round(value * 100) / 100
}

export default function ToggleButtonsVoyagePlanner({
  FLASK_URL,
  calculatorDepartureTime,
  setCalculatorDepartureTime,
  calculatorBasespeed,
  setCalculatorBasespeed,
  setToogleButtonsOnOff,
  voyagePlannerPoints,
  setVoyagePlannerPoints,
  setVoyageActive,
  switchVoyagePlanner,
  setSwitchVoyagePlanner,
  setPathfinderRoutes,
  etaResponse,
  setEtaResponse,
}) {
  const accessContext = useAccessContext()
  const accessToken = accessContext.token

  // console.log("ToggleButtonsVoyagePlanner", voyagePlannerPoints)
  const [startInput, setStartInput] = useState("")
  const [finishInput, setFinishInput] = useState("")
  const [startError, setStartError] = useState(false)
  const [finishError, setFinishError] = useState(false)
  const [openVoyageEstimator, setOpenVoyageEstimator] = useState(false)
  const [basespeedError, setBasespeedError] = useState(false)
  const [loadingRequest, setLoadingRequest] = useState(false)
  const [loadingPathfinder, setLoadingPathfinder] = useState(false)
  const handleSwitchChange = (event) => {
    const value = event.target.name

    if (event.target.checked) {
      setToogleButtonsOnOff((prevState) => [...prevState, value])
      if (value === "VoyagePlanner") {
        // Assuming the switch's name is "VoyagePlanner"
        setSwitchVoyagePlanner(true)
      }
    } else {
      setToogleButtonsOnOff((prevState) => prevState.filter((item) => item !== value))
      if (value === "VoyagePlanner") {
        setSwitchVoyagePlanner(false)
      }
    }
  }

  useEffect(() => {
    if (voyagePlannerPoints.features.length > 0) {
      const startCoords = `${formatCoord(voyagePlannerPoints.features[0].geometry.coordinates[1])},${formatCoord(
        voyagePlannerPoints.features[0].geometry.coordinates[0]
      )}`
      setStartInput(startCoords)
    }
    if (voyagePlannerPoints.features.length > 1) {
      const finishCoords = `${formatCoord(
        voyagePlannerPoints.features[voyagePlannerPoints.features.length - 1].geometry.coordinates[1]
      )},${formatCoord(voyagePlannerPoints.features[voyagePlannerPoints.features.length - 1].geometry.coordinates[0])}`
      setFinishInput(finishCoords)
    }
  }, [voyagePlannerPoints])

  const validateCoords = (coords) => {
    const parts = coords.split(",")
    const floatRegex = /^-?\d+(\.\d+)?$/
    return parts.length === 2 && parts.every((part) => floatRegex.test(part.trim()))
  }

  const handleInputChange = (setter, setError, coordsSetter) => (event) => {
    const input = event.target.value
    setter(input)
    const isValid = validateCoords(input)
    setError(!isValid) // Set the error state based on validity
    if (isValid) {
      const [lat, lng] = input.split(",").map(Number)
      coordsSetter(lat, lng)
    }
  }

  const handleStartChange = handleInputChange(setStartInput, setStartError, (lat, lng) => {
    // console.log("handle", voyagePlannerPoints)
    // Create a shallow copy of the voyagePlannerPoints
    let newPoints = turf.featureCollection([...voyagePlannerPoints.features])

    if (newPoints.features.length === 0) {
      // If there are no points, add the new start point
      newPoints.features.push(
        turf.point([lng, lat], {
          legtype: "greatcircle", // Adding properties as needed
          number: 1, // Assuming it's the first point
        })
      )
    } else {
      // Otherwise, update the existing start point
      newPoints.features[0].geometry.coordinates = [lng, lat]
    }

    // console.log("handleStartChange", { geometry: { coordinates: [lng, lat] } })
    setVoyagePlannerPoints(newPoints)
  })

  const handleFinishChange = handleInputChange(setFinishInput, setFinishError, (lat, lng) => {
    // Create a shallow copy of the voyagePlannerPoints
    let newPoints = turf.featureCollection([...voyagePlannerPoints.features])

    if (newPoints.features.length > 1) {
      // Update the existing finish point
      newPoints.features[newPoints.features.length - 1].geometry.coordinates = [lng, lat]
    } else if (newPoints.features.length === 1) {
      // Only start is set, add finish
      newPoints.features.push(
        turf.point([lng, lat], {
          legtype: "greatcircle", // Adding properties as needed
          number: 2, // Assuming it's the second point
        })
      )
    }

    // console.log("handleFinishChange", newPoints)
    setVoyagePlannerPoints(newPoints)
  })

  const handleClearStartInput = () => {
    setStartInput("") // Clear the input field
    setStartError(false) // Reset any errors
    if (voyagePlannerPoints.features.length > 1) {
      // Remove the first point and keep the rest
      // console.log("handleFinishChange", voyagePlannerPoints)
      const updatedPoints = turf.featureCollection(voyagePlannerPoints.features.slice(1))
      // console.log("handleFinishChange", updatedPoints)
      setVoyagePlannerPoints(updatedPoints)
    } else {
      // If only one point, clear all points
      setVoyagePlannerPoints(turf.featureCollection([]))
    }
  }

  const handleClearFinishInput = () => {
    setFinishInput("") // Clear the input field
    setFinishError(false) // Reset any errors
    if (voyagePlannerPoints.features.length > 1) {
      // Remove the last point

      const updatedPoints = turf.featureCollection(voyagePlannerPoints.features.slice(0, -1))
      setVoyagePlannerPoints(updatedPoints)
    } else {
      // If only one point, clear all points
      setVoyagePlannerPoints(turf.featureCollection([]))
    }
  }

  const handleVoyageEstimator = () => {
    setOpenVoyageEstimator(!openVoyageEstimator)
  }

  const handleDateChange = (newValue) => {
    setCalculatorDepartureTime(newValue)
  }

  const handleBasespeedInputChange = (event) => {
    const value = event.target.value
    const regex = /^\d+(\.\d*)?$/

    if (value === "" || regex.test(value)) {
      setCalculatorBasespeed(value)
      setBasespeedError(false)
    } else {
      setBasespeedError(true)
    }
  }

  function handleETAButtonClick() {
    setLoadingRequest(true)
    setSwitchVoyagePlanner(false)
    setVoyageActive(false)
    EtaCalculatorFlask({
      FLASK_URL,
      accessToken,
      voyagePlannerPoints,
      calculatorDepartureTime,
      calculatorBasespeed,
      setLoadingRequest,
      setEtaResponse,
    })
  }

  function handlePathfinderButtonClick() {
    setLoadingPathfinder(true)
    setSwitchVoyagePlanner(false)
    setVoyageActive(false)
    Pathfinder({
      FLASK_URL,
      accessToken,
      voyagePlannerPoints,
      calculatorDepartureTime,
      calculatorBasespeed,
      setLoadingPathfinder,
      setPathfinderRoutes,
      setEtaResponse,
    })
  }

  const isButtonEnabled =
    voyagePlannerPoints.features.length > 1 && calculatorBasespeed !== "" && calculatorDepartureTime.isValid()

  // console.log(etaResponse)
  return (
    <List component="div" disablePadding>
      <ListItem>
        <LocationOnIcon sx={{ color: "green", mr: 1, my: 0 }} />
        <FormControl error={startError} variant="outlined" sx={{ mb: -1 }} size="small">
          <InputLabel htmlFor="input-start">Start (lat/lon)</InputLabel>
          <OutlinedInput
            id="input-start"
            value={startInput}
            onChange={handleStartChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handleClearStartInput} size="small">
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            }
            label="Start (lat/lon)"
          />
        </FormControl>
      </ListItem>
      <ListItem>
        <MoreVertIcon sx={{ mr: 1, my: -2 }} />
      </ListItem>
      <ListItem>
        <LocationOnIcon sx={{ color: "red", mr: 1, my: 0 }} />
        <FormControl error={finishError} variant="outlined" sx={{ my: -1 }} size="small">
          <InputLabel htmlFor="input-finish">Finish (lat/lon)</InputLabel>
          <OutlinedInput
            id="input-finish"
            value={finishInput}
            onChange={handleFinishChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handleClearFinishInput} size="small">
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            }
            label="Finish (lat/lon)"
          />
        </FormControl>
      </ListItem>

      <ListItem>
        {/* <div style={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            control={<Switch checked={switchVoyagePlanner} onChange={handleSwitchChange} name="VoyagePlanner" />}
            label={<Typography>Create Voyage</Typography>}
          />
        </div> */}
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{ backgroundColor: "#004f7d", textTransform: "none" }}
            variant="contained"
            onClick={() => {
              setVoyagePlannerPoints(turf.featureCollection([]))
              setVoyageActive(true)
              setStartInput("")
              setFinishInput("")
              setStartError(false)
              setFinishError(false)
              setEtaResponse(false)
              setPathfinderRoutes({})
            }}
          >
            <Typography>Reset</Typography>
          </Button>
        </div>
      </ListItem>

      <ListItemButton
        sx={{
          backgroundColor: "background.paper",
        }}
        onClick={handleVoyageEstimator}
      >
        <ListItemText primary="Calculator" />
        {openVoyageEstimator ? (
          <ExpandLess sx={{ color: "#004f7d", ml: 2 }} />
        ) : (
          <ExpandMore sx={{ color: "#004f7d", ml: 2 }} />
        )}
      </ListItemButton>
      <ListItem sx={{ mb: -2 }}>
        <Collapse
          in={openVoyageEstimator}
          timeout="auto"
          unmountOnExit
          sx={{
            border: 1,
            borderColor: "divider",
            borderRadius: 1,
            mt: -1,
            mb: 3,
            padding: 1,
          }}
        >
          <List>
            <Typography sx={{ mb: 1 }}>Configuration:</Typography>
            <ListItem sx={{ m: 0, p: 0 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  slots={{ textField: TextField }}
                  label="Departure Time (UTC)"
                  value={calculatorDepartureTime}
                  onChange={handleDateChange}
                  minDateTime={dayjs()}
                />
              </LocalizationProvider>
            </ListItem>

            <ListItem sx={{ m: 0, p: 0 }}>
              <TextField
                fullWidth
                label="Basespeed (kn)"
                variant="outlined"
                sx={{ mt: 2 }}
                value={calculatorBasespeed}
                onChange={handleBasespeedInputChange}
                error={basespeedError}
              />
            </ListItem>

            {/* <ListItem sx={{ m: 0, p: 0 }}>
              <LoadingButton
                disabled={!isButtonEnabled}
                fullWidth
                variant="contained"
                loading={loadingRequest}
                sx={{ mt: 2, backgroundColor: "#004f7d", textTransform: "none" }}
                onClick={handleETAButtonClick}
              >
                <Typography>ETA</Typography>
              </LoadingButton>
            </ListItem> */}

            <ListItem sx={{ m: 0, p: 0 }}>
              <LoadingButton
                disabled={!isButtonEnabled}
                fullWidth
                variant="contained"
                loading={loadingPathfinder}
                sx={{ mt: 2, backgroundColor: "#004f7d", textTransform: "none" }}
                onClick={handlePathfinderButtonClick}
              >
                <Typography>Pathfinder</Typography>
              </LoadingButton>
            </ListItem>

            {etaResponse && (
              <ListItem sx={{ m: 0, p: 0, mt: 2 }}>
                <Typography>{`ETA: ${etaResponse}`}</Typography>
              </ListItem>
            )}
          </List>
        </Collapse>
      </ListItem>
    </List>
  )
}
