import * as React from "react"
import { useState, useEffect, Profiler, useMemo, useRef } from "react"
import { useMap } from "react-leaflet"
import { fromJS } from "immutable"

import { Paper } from "@mui/material"

import TimeSlider from "../timeslider/timeSlider"
import VesselIcon from "./VesselIcon"
import VesselGraph from "./VesselGraph"
import RouteHelper from "../../utils/routeHelper"
import Helper from "../../utils/Helper"

import dayjs from "dayjs" // neede for ...Picker.unix()

const extractTimestamps = (geoServerCapabilities) => {
  const gfsTimestamps = geoServerCapabilities?.GFS?.T?.Dimension || []
  const copCurTimestamps = geoServerCapabilities?.COP?.CUR?.Dimension || []
  const nww3WdmeanTimestamps = geoServerCapabilities?.NWW3?.WDMEAN?.Dimension || []

  const allTimestamps = [...gfsTimestamps, ...copCurTimestamps, ...nww3WdmeanTimestamps]

  const uniqueTimestamps = [...new Set(allTimestamps)].sort((a, b) => new Date(a) - new Date(b))

  return uniqueTimestamps
}

const extractCommonTimestamps = (geoServerCapabilities) => {
  // Helper function to get the first available Dimension array from a model
  const getFirstDimension = (model) => {
    // Check if the model and its first parameter exist
    if (model && Object.keys(model).length > 0) {
      const firstParameter = Object.values(model)[0]
      if (firstParameter && Object.keys(firstParameter).length > 0) {
        return Object.values(firstParameter)[0].Dimension || [] // Return Dimension if it exists, otherwise an empty array
      }
    }
    return []
  }

  // Extract timestamp arrays from the first parameter of each model
  const gfsTimestamps = getFirstDimension(geoServerCapabilities?.GFS || {})
  const copCurTimestamps = getFirstDimension(geoServerCapabilities?.COP || {})
  const nww3WdmeanTimestamps = getFirstDimension(geoServerCapabilities?.NWW3 || {})
  // Convert timestamps to a Set for fast lookup
  const gfsSet = new Set(gfsTimestamps)
  const copSet = new Set(copCurTimestamps)
  const nww3Set = new Set(nww3WdmeanTimestamps)

  // Find common timestamps
  const commonTimestamps = gfsTimestamps.filter((timestamp) => copSet.has(timestamp) && nww3Set.has(timestamp))

  // Sort the common timestamps
  commonTimestamps.sort((a, b) => new Date(a) - new Date(b))

  return commonTimestamps
}

export default function VesselController({
  tritonRealWaypointsGeojson,
  vesselRealRoutes,
  vesselPlanRoutes,
  vesselSuggestRoutes,
  pathfinderRoutes,
  markedVesselNames,
  switchReported,
  switchPlan,
  switchSuggest,
  fromPicker,
  toPicker,
  setCenterLatLonMap,
  setMarkedVesselNames,
  setOpenDrawer,
  setOpenVesselList,
  drawerWidth,
  openDrawer,
  drawerClosedWidthValue,
  isPaperOpen,
  setIsPaperOpen,
  selectedVessel,
  setSelectedVessel,
  resized,
  sliderPosition,
  setSliderPosition,
  selectedModel,
  setSelectedModel,
  selectedParams,
  setSelectedParams,
  geoServerCapabilities,
}) {
  // const start = Date.now()
  // console.log("VesselController", geoServerCapabilities)
  const map = useMap()
  const layers = []
  const [showChart, setShowChart] = useState(false)

  const [timesliderWidth, setTimesliderWidth] = useState(null)
  const paperRef = useRef(null)
  useEffect(() => {
    if (paperRef.current) {
      setTimesliderWidth(paperRef.current.offsetWidth)
    }
  }, [openDrawer, drawerWidth, resized])

  const timesliderPaperWidth = openDrawer ? `calc(100% - ${drawerWidth}px)` : `calc(100% - 57px)`

  function onRenderCallback(id, phase, actualDuration, baseDuration, startTime, commitTime, interactions) {
    console.log(`${id} took: ${actualDuration} ms`)
  }

  let sliderTicks = []
  let sliderTickCounter = 0
  let stepHours = 3 // when 6 then timesldier values are missing

  let filteredForecastTime = []
  if (geoServerCapabilities && Object.keys(geoServerCapabilities).length > 0 && filteredForecastTime.length === 0) {
    // filteredForecastTime = geoServerCapabilities?.GFS?.T?.Dimension
    filteredForecastTime = extractCommonTimestamps(geoServerCapabilities)
    // console.log(filteredForecastTime)
    // Add entries in steps of stepHours if fromPicker is before the first entry
    let firstForecastTime = dayjs.utc(filteredForecastTime[0])
    if (fromPicker.isBefore(firstForecastTime)) {
      let currentTime = fromPicker
      while (currentTime.isBefore(firstForecastTime)) {
        let feature = {
          value: sliderTickCounter,
          label: currentTime.format("YYYY-MM-DDTHH:mm:ss") + ".000Z", // Format the timestamp
        }
        sliderTicks.push(feature)
        currentTime = currentTime.add(stepHours, "hour")
        sliderTickCounter += stepHours
      }
    }

    let differences = [0]
    for (let i = 0; i < filteredForecastTime.length - 1; i++) {
      let time1 = dayjs(filteredForecastTime[i])
      let time2 = dayjs(filteredForecastTime[i + 1])
      let diff = time2.subtract(time1) / 60 / 60 / 1000
      differences.push(diff)
      if (dayjs.utc(filteredForecastTime[i]).isSameOrAfter(toPicker)) {
        break
      }
    }

    for (let i = 0; i < filteredForecastTime.length; i++) {
      sliderTickCounter = sliderTickCounter + differences[i]
      let feature = { value: sliderTickCounter, label: filteredForecastTime[i] }
      sliderTicks.push(feature)
      if (dayjs.utc(filteredForecastTime[i]).isSameOrAfter(toPicker)) {
        break
      }
    }

    // Check if toPicker is after the last entry of uniqueForecastTime
    let lastForecastTime = dayjs.utc(filteredForecastTime[filteredForecastTime.length - 1])
    if (toPicker.isAfter(lastForecastTime)) {
      // Add entries in steps of stepHours until reaching toPicker
      let currentTime = lastForecastTime.add(stepHours, "hour")
      let feature
      while (currentTime.isBefore(toPicker) || currentTime.isSame(toPicker)) {
        sliderTickCounter += stepHours
        feature = { value: sliderTickCounter, label: currentTime.format("YYYY-MM-DDTHH:mm:ss") + ".000Z" }
        sliderTicks.push(feature)
        currentTime = currentTime.add(stepHours, "hour")
      }
      sliderTickCounter += stepHours
      feature = { value: sliderTickCounter, label: currentTime.format("YYYY-MM-DDTHH:mm:ss") + ".000Z" }
      sliderTicks.push(feature)
    }
  } else {
    let currentTime = fromPicker
    let feature
    while (currentTime.isBefore(toPicker) || currentTime.isSame(toPicker)) {
      sliderTickCounter += stepHours
      feature = { value: sliderTickCounter, label: currentTime.format("YYYY-MM-DDTHH:mm:ss") + ".000Z" }
      sliderTicks.push(feature)
      currentTime = currentTime.add(stepHours, "hour")
    }
    sliderTickCounter += stepHours
    feature = { value: sliderTickCounter, label: currentTime.format("YYYY-MM-DDTHH:mm:ss") + ".000Z" }
    sliderTicks.push(feature)
  }

  useEffect(() => {
    if (sliderTicks.length > 0) {
      const currentTimeUTC = dayjs().utc()
      // console.log(sliderTicks, currentTimeUTC.format())
      // Filter ticks to find future ticks only
      const futureTicks = sliderTicks.filter((tick) => dayjs(tick.label).isAfter(currentTimeUTC))
      // console.log(futureTicks)
      if (futureTicks.length > 0) {
        // Find the nearest future tick
        const nearestFutureTick = futureTicks.reduce((prev, curr) =>
          dayjs(curr.label).diff(currentTimeUTC) < dayjs(prev.label).diff(currentTimeUTC) ? curr : prev
        )

        // console.log(nearestFutureTick)
        setSliderPosition(nearestFutureTick)
      }
    }
  }, [fromPicker, toPicker])

  const addVesselIcon = (vessels) => {
    layers.push(
      // <Profiler id="VesselIcon" onRender={onRenderCallback}>
      <VesselIcon
        vessels={vessels}
        markedVesselNames={markedVesselNames}
        setCenterLatLonMap={setCenterLatLonMap}
        setMarkedVesselNames={setMarkedVesselNames}
        setOpenDrawer={setOpenDrawer}
        setOpenVesselList={setOpenVesselList}
        isPaperOpen={isPaperOpen}
        setIsPaperOpen={setIsPaperOpen}
        selectedVessel={selectedVessel}
        setSelectedVessel={setSelectedVessel}
      />
      // </Profiler>
    )
  }

  const memoizedVesselGraph = useMemo(() => {
    return (
      <VesselGraph
        tritonRealWaypointsGeojson={tritonRealWaypointsGeojson}
        vesselRealRoutes={vesselRealRoutes}
        vesselPlanRoutes={vesselPlanRoutes}
        markedVesselNames={markedVesselNames}
        switchReported={switchReported}
        showChart={showChart}
        openDrawer={openDrawer}
        timesliderWidth={timesliderWidth}
        sliderTicks={sliderTicks}
      />
    )
  }, [vesselRealRoutes, vesselPlanRoutes, switchReported, showChart, openDrawer, timesliderWidth])

  if (switchReported) {
    let vessels = RouteHelper.buildVesselsGeojson({ routes: vesselRealRoutes, sliderPosition })
    addVesselIcon(vessels)
  }
  if (switchPlan) {
    let vessels = RouteHelper.buildVesselsGeojson({ routes: vesselPlanRoutes, sliderPosition })
    addVesselIcon(vessels)
  }
  if (switchSuggest) {
    let vessels = RouteHelper.buildVesselsGeojson({ routes: vesselSuggestRoutes, sliderPosition })
    addVesselIcon(vessels)
  }
  if (!switchReported && !switchPlan && !switchSuggest) {
    let vessels = RouteHelper.buildVesselsGeojson({
      routes: vesselRealRoutes,
      sliderPosition: sliderTicks[sliderTicks.length - 1],
    })
    addVesselIcon(vessels)
  }

  layers.push(
    // <div style={{ width: timesliderWidth, marginLeft: openDrawer ? `${drawerWidth}px` : "0" }}>
    <Paper
      //https://stackoverflow.com/questions/64437693/react-material-ui-slider-leafet-stopeventpropagation
      key="timeslider-paper"
      ref={paperRef}
      sx={{
        position: "absolute",
        bottom: 0,
        // left: "50%",
        // transform: "translateX(-50%)",
        // width: 1200,
        // boxShadow: "0 0 10px rgba(0,0,0,0.5)",
        zIndex: 10012,
        backgroundColor: "white",
        padding: "0px",
        borderRadius: "0px",
        color: "#555",
        width: timesliderPaperWidth,
        boxShadow: "0 0 0px rgba(0,0,0,0)",
        marginLeft: openDrawer ? `${drawerWidth}px` : `57px`,
        // transition: "width 0.5s ease, marginLeft 0.5s ease",
      }}
      onMouseEnter={() => Helper.onMouseEnter(map)}
      onMouseLeave={() => Helper.onMouseLeave(map)}
    >
      <TimeSlider
        vesselRealRoutes={vesselRealRoutes}
        pathfinderRoutes={pathfinderRoutes}
        switchReported={switchReported}
        switchPlan={switchPlan}
        switchSuggest={switchSuggest}
        sliderPosition={sliderPosition}
        setSliderPosition={setSliderPosition}
        sliderTicks={sliderTicks}
        showChart={showChart}
        setShowChart={setShowChart}
      />
      {memoizedVesselGraph}
    </Paper>
    // </div>
  )

  // const end = Date.now()
  // console.log(`VesselController took: ${end - start} ms`)
  return <>{layers}</>
}
