import React, { useState, useEffect } from "react"
import { GeoJSON, LayerGroup, useMap } from "react-leaflet"
import L from "leaflet"

import EtaCalculatorFlask from "../voyagePlanner/EtaCalculatorFlask"
import RouteHelper from "../../utils/routeHelper"
import { useAccessContext } from "../../modules/main/AccessContext"

export default function RoutePoints({
  FLASK_URL,
  vesselRoutes,
  setVesselRoutes,
  mapBounds,
  calculatorDepartureTime,
  calculatorBasespeed,
  setEtaResponse,
  setPathfinderRoutes,
}) {
  const accessContext = useAccessContext()
  const accessToken = accessContext.token
  const map = useMap()
  map.createPane("routePointsPane")
  map.getPane("routePointsPane").style.zIndex = 700
  map.createPane("popupPane")
  map.getPane("popupPane").style.zIndex = 800

  const [draggingMarker, setDraggingMarker] = useState(null) // Track the marker being dragged
  const [draggingFeature, setDraggingFeature] = useState(null) // Track the feature being dragged

  useEffect(() => {
    const onMouseMove = (e) => {
      if (draggingMarker) {
        const latlng = e.latlng
        draggingMarker.setLatLng(latlng) // Update the position of the dragged marker
      }
    }

    const onMouseUp = () => {
      if (draggingMarker && draggingFeature) {
        const latlng = draggingMarker.getLatLng()
        const updatedCoordinates = [latlng.lng, latlng.lat]
        // Update both points and corresponding lines
        const updatedVesselRoutes = vesselRoutes.map((route) => {
          // Update points in geojson
          const updatedPoints = route.points.geojson.features.map((feature) => {
            if (feature === draggingFeature) {
              return {
                ...feature,
                geometry: {
                  ...feature.geometry,
                  coordinates: updatedCoordinates,
                },
              }
            }
            return feature
          })

          // Update lines in geojson
          const updatedLines = route.lines.geojson.features.map((lineFeature) => {
            if (lineFeature.geometry.coordinates[0].toString() === draggingFeature.geometry.coordinates.toString()) {
              lineFeature.geometry.coordinates[0] = updatedCoordinates
              lineFeature.properties.analyse.wp1.geometry.coordinates = updatedCoordinates
            } else if (
              lineFeature.geometry.coordinates[1].toString() === draggingFeature.geometry.coordinates.toString()
            ) {
              lineFeature.geometry.coordinates[1] = updatedCoordinates
              lineFeature.properties.analyse.wp2.geometry.coordinates = updatedCoordinates
            }
            return lineFeature
          })
          return {
            ...route,
            points: {
              ...route.points,
              geojson: {
                ...route.points.geojson,
                features: updatedPoints,
              },
            },
            lines: {
              ...route.lines,
              geojson: {
                ...route.lines.geojson,
                features: updatedLines,
              },
            },
          }
        })

        let voyagePlannerPoints = updatedVesselRoutes[0].points.geojson
        // console.log("points after dragging", voyagePlannerPoints)
        EtaCalculatorFlask({
          FLASK_URL,
          accessToken,
          voyagePlannerPoints,
          calculatorDepartureTime,
          calculatorBasespeed,
          setEtaResponse,
          setPathfinderRoutes,
        })
        // Update the vesselRoutes with new coordinates and lines
        setVesselRoutes(updatedVesselRoutes)

        setDraggingMarker(null) // Stop tracking the dragged marker
        setDraggingFeature(null) // Stop tracking the dragged feature
        map.dragging.enable() // Re-enable map dragging
      }
    }

    // Attach global mousemove and mouseup events to the map
    map.on("mousemove", onMouseMove)
    map.on("mouseup", onMouseUp)

    // Clean up when the component unmounts
    return () => {
      map.off("mousemove", onMouseMove)
      map.off("mouseup", onMouseUp)
    }
  }, [map, draggingMarker, draggingFeature, vesselRoutes, setVesselRoutes])

  // Function to delete the point and connect the remaining points
  const handleRightClick = (featureToDelete) => {
    const updatedVesselRoutes = vesselRoutes.map((route) => {
      // Find the index of the feature to delete
      const pointIndex = route.points.geojson.features.findIndex((feature) => feature === featureToDelete)

      // Identify the previous and next points
      const previousPoint = route.points.geojson.features[pointIndex - 1]?.geometry.coordinates
      const nextPoint = route.points.geojson.features[pointIndex + 1]?.geometry.coordinates

      // Update the points by removing the deleted point
      const updatedPoints = route.points.geojson.features.filter((_, idx) => idx !== pointIndex)

      // Update the lines by removing the ones connected to the deleted point
      const updatedLines = route.lines.geojson.features.filter(
        (lineFeature) =>
          lineFeature.geometry.coordinates[0].toString() !== featureToDelete.geometry.coordinates.toString() &&
          lineFeature.geometry.coordinates[1].toString() !== featureToDelete.geometry.coordinates.toString()
      )

      // If both previous and next points exist, create a new line connecting them
      if (previousPoint && nextPoint) {
        const newLine = {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [previousPoint, nextPoint],
          },
          properties: {
            analyse: {
              wp1: { geometry: { previousPoint }, properties: {}, type: "Feature" },
              wp2: { geometry: { nextPoint }, properties: {}, type: "Feature" },
            },
            type: "Feature",
          },
        }
        // Find where the next point's previous connection was and insert the new line there
        const nextLineIndex = route.lines.geojson.features.findIndex(
          (lineFeature) =>
            lineFeature.geometry.coordinates[0].toString() === nextPoint.toString() ||
            lineFeature.geometry.coordinates[1].toString() === nextPoint.toString()
        )

        // Insert the new line at the correct index
        updatedLines.splice(nextLineIndex - 1, 0, newLine)
      }

      return {
        ...route,
        points: {
          ...route.points,
          geojson: {
            ...route.points.geojson,
            features: updatedPoints,
          },
        },
        lines: {
          ...route.lines,
          geojson: {
            ...route.lines.geojson,
            features: updatedLines,
          },
        },
      }
    })
    // console.log("points after delete", updatedVesselRoutes)
    let voyagePlannerPoints = updatedVesselRoutes[0].points.geojson
    EtaCalculatorFlask({
      FLASK_URL,
      accessToken,
      voyagePlannerPoints,
      calculatorDepartureTime,
      calculatorBasespeed,
      setEtaResponse,
      setPathfinderRoutes,
    })

    setVesselRoutes(updatedVesselRoutes)
  }

  return vesselRoutes === null ? null : (
    <LayerGroup>
      {vesselRoutes.map((route) => {
        let routePoints = route.points
        // let filteredData = RouteHelper.markBoundingboxPoints(routePoints, mapBounds).geojson
        let filteredData = routePoints.geojson
        // console.log(`route length: ${routePoints.geojson.features.length}, simplified: ${filteredData.features.length}`);
        return routePoints.geojson.features === null || routePoints.geojson.features.length === null ? null : (
          <GeoJSON
            key={`RoutePoints-${routePoints.routeId}-${mapBounds}-${JSON.stringify(filteredData.features)}`}
            data={filteredData}
            style={{
              // fillColor: RouteHelper.findRouteColor(routePoints.routeType),
              // color: RouteHelper.findRouteColor(routePoints.routeType),
              // weight: 1,
              fillOpacity: 1,
            }}
            pointToLayer={(feature, latlon) => {
              // Determine the color based on the feature's properties
              let markerProps = {
                radius: 3,
                weight: 1,
                opacity: 1,
                fillOpacity: 1,
                fillColor: "blue",
                color: "blue",
                pane: "routePointsPane",
                // zIndex: 1011,
              }

              if (
                route.points.routeType === "real" &&
                feature.properties.albis_reports !== undefined &&
                feature.properties.albis_reports !== null
              ) {
                markerProps.radius = 7
                markerProps.weight = 3
                markerProps.color = "white"
              } else if (route.points.routeType === "plan") {
                markerProps.fillColor = "green"
                markerProps.color = "green"
              } else if (route.points.routeType === "suggest") {
                markerProps.fillColor = "red"
                markerProps.color = "red"
              } else if (route.points.routeType === "Pathfinder") {
                markerProps.fillColor = "purple"
                markerProps.color = "purple"
              }

              // Create a draggable circleMarker
              const marker = L.circleMarker(latlon, markerProps)

              // Add mousedown event to start dragging the marker
              if (route.points.routeType === "Pathfinder") {
                marker.on("mousedown", (e) => {
                  setDraggingMarker(marker) // Set this marker as the one being dragged
                  setDraggingFeature(feature) // Set the feature being dragged
                  map.dragging.disable() // Disable map dragging when dragging marker
                })

                // Right-click event to delete the marker
                marker.on("contextmenu", () => {
                  handleRightClick(feature) // Call function to delete the point
                })
              }

              return marker
            }}
            onEachFeature={(feature, layer) => {
              RouteHelper.applyWpPopup({ routePoints, feature, layer, pane: "popupPane" })
            }}
          />
        )
      })}
    </LayerGroup>
  )
}
