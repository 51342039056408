import PostGraphileApi from "../../utils/PostGraphileApi"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import * as turf from "@turf/turf" // GIS/GeoJSON library

const windAndWaveProfile = {
  name: "Default",
  deletable: "true",
  children: [
    {
      name: "wind",
      deletable: "true",
      rows: [0.0, 45.0, 90.0, 135.0, 180.0],
      data: [
        [100.0, 100.0, 95.0, 85.0, 75.0],
        [100.0, 100.0, 100.0, 90.0, 85.0],
        [100.0, 100.0, 100.0, 100.0, 100.0],
        [100.0, 100.0, 100.0, 102.0, 105.0],
        [100.0, 100.0, 102.0, 105.0, 110.0],
      ],
      columns: [2.0, 4.0, 6.0, 8.0, 10.0],
      editable: "true",
    },
    {
      name: "wave",
      deletable: "true",
      rows: [0.0, 45.0, 90.0, 135.0, 180.0],
      data: [
        [95.0, 80.0, 50.0, 10.0, 10.0],
        [97.0, 90.0, 80.0, 30.0, 15.0],
        [100.0, 95.0, 70.0, 40.0, 25.0],
        [100.0, 95.0, 75.0, 60.0, 40.0],
        [100.0, 98.0, 85.0, 75.0, 50.0],
      ],
      columns: [2.0, 4.0, 6.0, 8.0, 10.0],
      editable: "true",
    },
  ],
  editable: "true",
}

function pathfinderResponseToRouteFormat(pathfinderRoutes) {
  const waypoints = pathfinderRoutes.baseRoute.wps
  // Use a single .map to generate both LineString and Point features
  const lineAndPointFeatures = waypoints.map((wp, index, array) => {
    // Convert Unix timestamp to ISO 8601 format
    const isoTimestamp = wp.timestamp ? dayjs.unix(wp.timestamp).utc().format("YYYY-MM-DDTHH:mm:ss[+00:00]") : null
    // Create Point feature for the current waypoint
    const point = turf.point([wp.lon, wp.lat], {
      dmean: wp.dmean,
      hssw: wp.hssw,
      legType: wp.legType,
      timestamp: isoTimestamp,
      u10: wp.u10,
      ucur: wp.ucur,
      v10: wp.v10,
      vcur: wp.vcur,
    })

    // Create LineString feature, ensuring we're not on the last waypoint
    const line =
      index < array.length - 1
        ? turf.lineString(
            [
              [wp.lon, wp.lat],
              [array[index + 1].lon, array[index + 1].lat],
            ],
            {
              analyse: {
                wp1: point,
                wp2: turf.point([array[index + 1].lon, array[index + 1].lat], {
                  dmean: array[index + 1].dmean,
                  hssw: array[index + 1].hssw,
                  legType: array[index + 1].legType,
                  timestamp: isoTimestamp,
                  u10: array[index + 1].u10,
                  ucur: array[index + 1].ucur,
                  v10: array[index + 1].v10,
                  vcur: array[index + 1].vcur,
                }),
              },
            }
          )
        : null

    return { point, line }
  })

  // Construct the final object with all steps included in the return statement
  return [
    {
      points: {
        geojson: turf.featureCollection(lineAndPointFeatures.map((item) => item.point)),
        routeId: "Pathfinder",
        routeType: "Pathfinder",
        vesselName: "Pathfinder",
      },
      lines: {
        geojson: turf.featureCollection(lineAndPointFeatures.map((item) => item.line).filter(Boolean)),
        routeId: "Pathfinder",
        routeType: "Pathfinder",
        vesselName: "Pathfinder",
      },
    },
  ]
}

// Main function to orchestrate voyage and route insertion
export default async function EtaCalculatorFlask({
  FLASK_URL,
  accessToken,
  voyagePlannerPoints,
  calculatorDepartureTime,
  calculatorBasespeed,
  setLoadingRequest,
  setEtaResponse,
  setPathfinderRoutes,
}) {
  const ETA_URL = FLASK_URL + "eta"
  // console.log("voyagePlannerPoints", voyagePlannerPoints)
  // Convert the geoJson object into the desired structure
  const convertedWps = voyagePlannerPoints.features.map((feature) => {
    return {
      legType: feature.properties.legtype === "greatcircle" ? 1 : feature.properties.legtype === "rhumbline" ? 0 : null,
      lat: feature.geometry.coordinates[1],
      lon: feature.geometry.coordinates[0],
    }
  })
  // console.log("convertedWps", convertedWps)

  // Wrap the converted data with the parent key baseRoute
  const voyageData = {
    departureTime: Math.floor(new Date(calculatorDepartureTime).getTime() / 1000),
    baseSpeed: parseInt(calculatorBasespeed, 10),
    windAndWaveProfile,
    baseRoute: {
      wps: convertedWps,
    },
  }
  // console.log("voyageData", voyageData)

  let headers = {
    Accept: "application/json", // response data format
    "Content-Type": "application/json", // request body data format
    "Accept-Encoding": "gzip, deflate", // kann fehlen, der browser erkennt's trotzdem
    Authorization: "Bearer " + accessToken, // Replace with actual token or omit if not needed
  }

  const request = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(voyageData),
  }
  console.log(ETA_URL, request)
  PostGraphileApi.fetchRequest(ETA_URL, request, "Error: ")
    .then((responseJson) => {
      // setLoadingRequest(false)
      let convertedPathfinderRoute = pathfinderResponseToRouteFormat(responseJson)
      // console.log("setPathfinderRoutes", convertedPathfinderRoute)
      // setPathfinderRoutes(convertedPathfinderRoute)

      setEtaResponse(
        dayjs
          .unix(responseJson.baseRoute.wps[responseJson.baseRoute.wps.length - 1].timestamp)
          .format("YYYY-MM-DD HH:mm:ss")
      )
    })
    .catch((error) => {
      // setLoadingRequest(false)
      console.error("Request failed:", error)
    })
}
