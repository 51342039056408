import React, { useMemo } from "react"
import ImageOverlayWithHeader from "./ImageOverlayWithHeader"
import { useAccessContext } from "../../modules/main/AccessContext"

import L from "leaflet"

export default function CoastlineLayer({ coastlineUrl, coastlineKey, latlngMapBounds, MAX_LATITUDE }) {
  const accessContext = useAccessContext()
  const accessToken = accessContext.token

  const memoizedCoastline = useMemo(() => {
    return (
      <ImageOverlayWithHeader
        key={coastlineKey}
        url={coastlineUrl}
        bounds={
          new L.LatLngBounds(
            [Math.max(latlngMapBounds.getSouth(), -MAX_LATITUDE), latlngMapBounds.getWest()],
            [Math.min(latlngMapBounds.getNorth(), MAX_LATITUDE), latlngMapBounds.getEast()]
          )
        }
        opacity={1}
        zIndex={1005}
        headers={{ Authorization: `Basic ${accessToken}` }}
        // onLoad={() => console.log("fetch coastline layer", coastlineKey)}
      />
    )
  }, [coastlineUrl, coastlineKey])

  return memoizedCoastline
}
