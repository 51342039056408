import * as React from "react"
import { useState, useEffect } from "react"
import { useAccessContext } from "../../modules/main/AccessContext"

import { GeoJSON } from "react-leaflet"

//import Helper from '../../modules/Helper';//.js';
import PostGraphileApi from "../../utils/PostGraphileApi"

export default function Highrisk({ fetch_url, toogleButtonsOnOff }) {
  const accessContext = useAccessContext()
  const accessToken = accessContext.token
  const [highriskGeojsons, sethighriskGeojsons] = useState(null)
  useEffect(() => {
    const graphqlQuery = "query MyQuery { highriskGeojsons { nodes { geojson}}}"
    PostGraphileApi.fetchRequest(
      fetch_url,
      PostGraphileApi.httpRequestData(graphqlQuery, accessToken),
      ".loadGraphql() fehler_1="
    )
      .then(({ data }) => {
        // console.log('###eca',data.ecaGeojsons.nodes);
        // console.warn(JSON.stringify(data))
        sethighriskGeojsons(data) // setData(data[graphqlKey].nodes);
      })
      .catch((error) => {
        console.log("highRisk.useEffect() error")
      })
  }, [])

  return !toogleButtonsOnOff.includes("HighRisk") || highriskGeojsons === null ? null : (
    <GeoJSON // extends FeatureGroup
      interactive={true} // keine mouse events
      transparent={true} // hintergrund zeichnen bei alpha-transparenz-bereichen dieses bildes
      data={highriskGeojsons.highriskGeojsons.nodes[0].geojson.features}
      style={{ fillColor: "red", color: "red", weight: 2 }} // style alternative 2
      onEachFeature={(feature, layer) => {
        layer.bindPopup("<div>Highrisk Area: " + feature.properties.name + "</div") // interactive={false} -> inaktiv
      }}
    />
  )
}
