const enviroments = {
  prodConfig: {
    SHIPPING_URL: "https://api.wetterwelt.de/graphql/shippingdb",
    METEO_URL: "https://api.wetterwelt.de/graphql/meteodb",
    GEO_URL: "https://api.wetterwelt.de/graphql/geodb",
    GEOSERVER_URL: "https://api.wetterwelt.de/geoserver/",
    FLASK_URL: "https://api.wetterwelt.de/",
  },

  directConfig: {
    SHIPPING_URL: "http://grib-gis-01.local/service-center/graphql",
    METEO_URL: "http://grib-gis-01.local/meteodb-wod/graphql",
    GEO_URL: "http://grib-gis-01.local/geodb/graphql",
    GEOSERVER_URL: "https://geoserver.wetterwelt.de/geoserver/",
    // GEOSERVER_URL: "https://geoserver.wetterwelt.de/geoserver/",
    FLASK_URL: "http://192.168.10.237:5000/",
  },
  devConfig: {
    SHIPPING_URL: "http://uss-01.local:8081/graphql/sc",
    METEO_URL: "http://uss-01.local:8081/graphql/meteodb",
    GEO_URL: "http://uss-01.local:8081/graphql/geodb",
    GEOSERVER_URL: "http://uss-01.local:8081/geoserver/",
    FLASK_URL: "http://uss-01.local:8081/",
  },
}

export default enviroments.prodConfig
