import { useMemo } from "react"
import { fromJS } from "immutable"
import dayjs from "dayjs"

import { RouteAnalyser } from "../modules/route_analyse/RouteAnalyser2"

export const useAnalysePlanSuggestRoutes = (fromPicker, toPicker, tritonPlanWaypointsGeojson, analysedRealRoutes) => {
  return useMemo(() => {
    if (tritonPlanWaypointsGeojson === null || analysedRealRoutes === null) {
      return null
    } else {
      let startTime
      // console.log("useAnalysePlanSuggestRoutes hook Memo")
      const rawRoutes = tritonPlanWaypointsGeojson.tritonWaypointsGeojson.nodes

      if (rawRoutes !== null) {
        const analysedRoutes = rawRoutes.flatMap((route) => {
          const startTime = performance.now()
          if (route.geojson.features.length > 1) {
            let newRoute = fromJS(route)
            if (newRoute.toJS().geojson.features.length < 2) {
              // console.log(`${newRoute.toJS().vesselName} route segment only low_distance`,{points: newRoute.toJS(), lines: {}});
              return { points: newRoute.toJS(), lines: {} }
            }

            // normale route analysis
            let analysedPoints = RouteAnalyser.analyseRoute(newRoute.toJS())
            // if(route.vesselName === 'Ardmore Endeavour'){
            //   console.log(analysedPoints);
            // }
            analysedPoints = RouteAnalyser.setTimeIntervalTree(fromJS(analysedPoints.points))
            analysedPoints = RouteAnalyser.setRouteInterpolatePointByTime(
              analysedPoints,
              dayjs.utc(fromPicker).format(),
              "legtype"
            )
            analysedPoints = RouteAnalyser.setTimeIntervalTree(analysedPoints)
            analysedPoints = RouteAnalyser.setRouteInterpolatePointByTime(
              analysedPoints,
              dayjs.utc(toPicker).format(),
              "legtype"
            )
            analysedPoints = RouteAnalyser.markBetweenTimeRange(
              analysedPoints,
              // dayjs(planRouteStartTimestamp),
              dayjs(fromPicker),
              dayjs(toPicker)
            )

            // waypoints2linesegments functions (with filter):
            analysedPoints = RouteAnalyser.filterRoute(analysedPoints, (wp, i, a) => {
              // i index , a array mit a.get(i-1)
              // If interpolated point in beginning or end of route or antimeridian crossing
              if (
                wp.getIn(["properties", "legtype"]) === "time_interpolated_point" ||
                wp.getIn(["properties", "type"]) === "antimeridian_point"
                // wp.getIn(["properties", "legtype"]) === "rhumbline_antimeridian" ||
                // wp.getIn(["properties", "legtype"]) === "greatcircle_antimeridian"
              ) {
                return true
              }
              if (
                wp.getIn(["properties", "analyse", "is_simplify"]) &&
                wp.getIn(["properties", "analyse", "is_in_timerange"])
              ) {
                return true
              }
            })

            // filter out wp before last reported WP and interpolate to last reported timestamp (only for current plan route)
            let analysedReportedRouteFeature = analysedRealRoutes.find(
              (reportedRoute) => reportedRoute.points.vesselName === route.vesselName
            )
            if (
              analysedPoints.toJS().geojson.features[analysedPoints.toJS().geojson.features.length - 1]?.properties
                .timestamp >
              analysedReportedRouteFeature.points.geojson.features[
                analysedReportedRouteFeature.points.geojson.features.length - 1
              ]?.properties.timestamp
            ) {
              let planRouteStartTimestamp = analysedReportedRouteFeature
                ? analysedReportedRouteFeature.points.geojson.features[
                    analysedReportedRouteFeature.points.geojson.features.length - 1
                  ].properties.timestamp
                : null

              if (analysedPoints.toJS().geojson.features.length > 2) {
                analysedPoints = RouteAnalyser.setTimeIntervalTree(analysedPoints)
                analysedPoints = RouteAnalyser.setRouteInterpolatePointByTime(
                  analysedPoints,
                  planRouteStartTimestamp,
                  "legtype"
                )
              }
              // analysedPoints = RouteAnalyser.filterRoute(analysedPoints, (wp, i, a) => {
              //   if (wp.getIn(["properties", "timestamp"]) >= planRouteStartTimestamp) {
              //     return true
              //   }
              // })
            }
            let analysedLines = RouteAnalyser.linestringSegments(
              analysedPoints,
              RouteAnalyser.THRESHOLDS.seg_distance_in_km
            )
            // console.log({ points: analysedPoints.toJS(), lines: analysedLines.toJS() })
            const endTime = performance.now()
            // console.log(
            //   `analyse route took ${endTime - startTime} ms`,
            //   analysedPoints.toJS().vesselName,
            //   analysedPoints.toJS().routeType
            // )
            return { points: analysedPoints.toJS(), lines: analysedLines.toJS() }
          } else {
            return []
          }
        })
        return analysedRoutes
      } else {
        return null
      }
    }
  }, [tritonPlanWaypointsGeojson, analysedRealRoutes])
}
