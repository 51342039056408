import * as React from "react"
import { useState, useEffect } from "react"
import { useAccessContext } from "../../modules/main/AccessContext"

import { GeoJSON, useMapEvents, useMap } from "react-leaflet"
import L from "leaflet"

import Helper from "../../utils/Helper" //.js';
import PostGraphileApi from "../../utils/PostGraphileApi"

export default function Port({ fetch_url, toogleButtonsOnOff, zoomLevel, dragged, resized }) {
  const accessContext = useAccessContext()
  const accessToken = accessContext.token
  const map = useMap()
  //  const [zoomLevel, setZoomLevel] = useState(3); // rc 2023-01-10: was passiert, wenn der start zoomlevel in MainComponent.js nicht 3 ist in <MapContainer zoom={...?
  //  const mapEvents = useMapEvents({
  //    zoomend: () => {
  //      setZoomLevel(mapEvents.getZoom());
  //    },
  //  });

  // ohne useEffect():
  function filterViewportBoundingbox(map, features) {
    //console.log(map.getBounds());
    const filterFeatures = features.filter((feature) => {
      return map.getBounds().contains([feature.geometry.coordinates[1], feature.geometry.coordinates[0]])
    })
    //console.log(filterFeatures)
    return filterFeatures
  }

  //console.log("Port():"+JSON.stringify({ fetch_url, toogleButtonsOnOff,zoomLevel, dragged,resized }))

  function getTonnageThreshold(zoomLevel) {
    const zoomTonnageThreshold = {
      3: 3000000,
      4: 1000000,
      5: 100000,
      6: 10000,
      7: 10000,
    }
    const minKey = Math.min(...Object.keys(zoomTonnageThreshold))
    const maxKey = Math.max(...Object.keys(zoomTonnageThreshold))
    let tonnageThreshold = zoomTonnageThreshold[zoomLevel]
    if (zoomLevel < minKey) {
      tonnageThreshold = zoomTonnageThreshold[minKey]
    } else if (zoomLevel > maxKey) {
      tonnageThreshold = 0
    }
    return tonnageThreshold
  }

  const [portGeojsons, setportGeojsons] = useState(null)
  useEffect(() => {
    const graphqlQuery = "query MyQuery { portGeojsons { nodes { geojson}}}"
    PostGraphileApi.fetchRequest(
      fetch_url,
      PostGraphileApi.httpRequestData(graphqlQuery, accessToken),
      ".loadGraphql() fehler_1="
    )
      .then(({ data }) => {
        // console.warn(JSON.stringify(data))
        setportGeojsons(data) // setData(data[graphqlKey].nodes);
      })
      .catch((error) => {
        console.log("Port.useEffect() error")
      })
  }, [])

  return !toogleButtonsOnOff.includes("Port") || portGeojsons === null ? null : (
    <GeoJSON // extends FeatureGroup
      key={[zoomLevel, dragged, resized]} // GeoJSON Neurendern erzwingen
      interactive={false} // keine mouse events
      transparent={true} // hintergrund zeichnen bei alpha-transparenz-bereichen dieses bildes
      data={filterViewportBoundingbox(map, portGeojsons.portGeojsons.nodes[0].geojson.features)}
      style={{ fillColor: "#black", color: "black", weight: 2 }} // style alternative 2
      //eventHandlers={{
      //  click: (e) => {
      //    console.log('marker clicked='+e.latlng)
      //  },
      //}}
      onEachFeature={(feature, layer) => {
        const tonnageThreshold = getTonnageThreshold(zoomLevel)
        if (feature.properties.tonnage >= tonnageThreshold) {
          //var divicon = L.divIcon({content:'jfjfjhfhjfjh' }); // Explicitly set to null or you will default to 12x12
          //L.marker([50.505, 30.57], {icon: divicon}).addTo(map);
          layer.bindTooltip("<div>" + feature.properties.name + "</div>", {
            permanent: true,
            direction: "center",
            opacity: 0.6,
            offset: L.point(0, -20),
            className: "my_tooltip_labels_ports", // siehe app.css und https://jsfiddle.net/maphew/gtdkxj8e/7/
          })
        }
        layer.bindPopup(
          "<div>Port: " +
            feature.properties.name +
            " <br> " +
            feature.geometry.coordinates +
            " <br> " +
            Helper.ConvertDDToDMS(feature.geometry.coordinates) +
            " <br> " +
            feature.properties.tonnage +
            "</div>"
        )
      }}
      pointToLayer={(feature, latlon) => {
        var featuresLayer = L.circle(latlon, {
          radius: 500,
          opacity: 1, // border
        })
        return featuresLayer
      }} // spezialfunktion nur für (geojson-)punkte, radius in m
    />
  )
}
