import React, { useState, useCallback } from "react"

import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import ReactDOMServer from "react-dom/server"
import * as turf from "@turf/turf"

import EtaCalculatorFlask from "../voyagePlanner/EtaCalculatorFlask"
import L from "leaflet"
import { GeoJSON, LayerGroup, Marker, useMap } from "react-leaflet"
import RouteHelper from "../../utils/routeHelper"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { min } from "lodash"
import { useAccessContext } from "../../modules/main/AccessContext"

dayjs.extend(utc)
dayjs.extend(localizedFormat)

// Create the marker icon using CircleOutlinedIcon from Material UI
const markerIcon = L.divIcon({
  className: "custom-marker",
  html: ReactDOMServer.renderToString(<CircleOutlinedIcon style={{ fontSize: 36, color: "purple" }} />),
  iconSize: [36, 36],
  iconAnchor: [18, 18],
  // pane: "routeLinePane",
  // zIndex: 1100,
})

// Function to find the closest point on a line
const findClosestPointOnLine = (latlng, line) => {
  const lineCoords = line.geometry.coordinates.map((coord) => L.latLng(coord[0], coord[1]))
  const latLngArray = lineCoords.map((latlng) => [latlng.lat, latlng.lng])
  const nearestPoint = turf.nearestPointOnLine(turf.lineString(latLngArray), [latlng.lng, latlng.lat])
  return L.latLng(nearestPoint.geometry.coordinates[1], nearestPoint.geometry.coordinates[0])
}

export default function RouteLines({
  FLASK_URL,
  vesselRoutes,
  setVesselRoutes,
  mapBounds,
  calculatorDepartureTime,
  calculatorBasespeed,
  setEtaResponse,
  setPathfinderRoutes,
}) {
  const accessContext = useAccessContext()
  const accessToken = accessContext.token
  const map = useMap()
  map.createPane("routeLinePane")
  map.getPane("routeLinePane").style.zIndex = 600

  const [hoverMarker, setHoverMarker] = useState(null) // Track the position of the marker to display

  const getStyle = useCallback(({ feature, routeType, isStroke }) => {
    if (routeType === "real") {
      let dashArray =
        feature.properties.analyse.wp2.properties &&
        feature.properties.analyse.wp2.properties.analyse.is_gap_time_by_timestamps
          ? "5,5"
          : "0"
      return {
        color: isStroke ? "white" : RouteHelper.findRouteColor(routeType),
        weight: isStroke ? 10 : 8,
        opacity: isStroke ? 1 : 0.6,
        dashArray: dashArray,
        pane: "routeLinePane",
        zIndex: isStroke ? 1020 : 1020,
        stroke: true,
      }
    } else if (routeType === "plan" || routeType === "suggest") {
      return {
        color: isStroke ? "white" : RouteHelper.findRouteColor(routeType), // White stroke, green line
        weight: isStroke ? 10 : 8, // Thicker stroke, thinner green line on top
        opacity: isStroke ? 1 : 0.6, // Fully opaque stroke and line
        dashArray: "0", // Solid line
        pane: "routeLinePane",
        zIndex: isStroke ? 1010 : 1010, // Ensure stroke is below the green line
        stroke: true,
      }
    } else if (routeType === "Pathfinder") {
      return {
        color: isStroke ? "white" : RouteHelper.findRouteColor("Pathfinder"),
        weight: isStroke ? 10 : 8,
        opacity: isStroke ? 1 : 0.6,
        dashArray: "0",
        pane: "routeLinePane",
        zIndex: isStroke ? 1010 : 1010,
        stroke: true,
      }
    }
  }, [])

  // Add event handlers for mouseover to show marker
  const handleMouseMove = (e, feature) => {
    const latlng = e.latlng
    const closestPoint = findClosestPointOnLine(latlng, feature)
    setHoverMarker(closestPoint) // Set the hover marker at the closest point on the line
  }

  const handleMouseOut = () => {
    setHoverMarker(null) // Remove the marker when the cursor leaves the line
  }

  // Handle click event to add a point on the line
  const handleLineClick = (e, lineSegment, route) => {
    // Create the new point at the clicked location
    const newPoint = turf.point([e.latlng.lng, e.latlng.lat], {})

    // Calculate distances to find the correct place to insert the new point
    const distances = route.points.geojson.features.map((point) => {
      return Math.sqrt(
        Math.pow(point.geometry.coordinates[1] - newPoint.geometry.coordinates[1], 2) +
          Math.pow(point.geometry.coordinates[0] - newPoint.geometry.coordinates[0], 2)
      )
    })

    if (distances.length < 2) return // Ensure there are enough points to calculate distances

    let minIndex = 0
    let minDistance = Infinity

    for (let i = 1; i < distances.length; i++) {
      const distance = distances[i - 1] + distances[i]
      if (distance < minDistance) {
        minDistance = distance
        minIndex = i
      }
    }

    // Insert the new point at the optimal position
    const updatedPoints = [...route.points.geojson.features]
    updatedPoints.splice(minIndex, 0, newPoint)

    // Reindex the points
    const reindexedPoints = updatedPoints.map((point, idx) => {
      return turf.point(point.geometry.coordinates, {
        ...point.properties,
        number: idx + 1,
        extended_number: 0,
      })
    })

    // Update the lines to reflect the new point
    const updatedLines = [...route.lines.geojson.features]

    // Remove the old line between the points where the new point is inserted
    const oldLine = updatedLines.find(
      (line) =>
        line.geometry.coordinates[0].toString() ===
          route.points.geojson.features[minIndex - 1]?.geometry.coordinates.toString() &&
        line.geometry.coordinates[1].toString() ===
          route.points.geojson.features[minIndex]?.geometry.coordinates.toString()
    )

    // Remove the old lines
    updatedLines.splice(updatedLines.indexOf(oldLine), 1)

    // Add two new lines to connect the new point
    const newLine1 = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [updatedPoints[minIndex - 1]?.geometry.coordinates, newPoint.geometry.coordinates],
      },
      properties: {
        analyse: {
          wp1: { geometry: updatedPoints[minIndex - 1]?.geometry, properties: {}, type: "Feature" },
          wp2: { geometry: newPoint.geometry, properties: {}, type: "Feature" },
        },
        type: "Feature",
      },
    }
    const newLine2 = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [newPoint.geometry.coordinates, updatedPoints[minIndex + 1]?.geometry.coordinates],
      },
      properties: {
        analyse: {
          wp1: { geometry: newPoint.geometry, properties: {}, type: "Feature" },
          wp2: { geometry: updatedPoints[minIndex + 1]?.geometry, properties: {}, type: "Feature" },
        },
        type: "Feature",
      },
    }

    // Add the new lines at the correct positions
    updatedLines.splice(minIndex - 1, 0, newLine1)
    updatedLines.splice(minIndex, 0, newLine2)

    // Update the vesselRoutes object
    const updatedVesselRoutes = vesselRoutes.map((r) => {
      if (r === route) {
        return {
          ...r,
          points: {
            ...r.points,
            geojson: {
              ...r.points.geojson,
              features: reindexedPoints,
            },
          },
          lines: {
            ...r.lines,
            geojson: {
              ...r.lines.geojson,
              features: updatedLines,
            },
          },
        }
      }
      return r
    })

    // console.log("lines after adding", updatedVesselRoutes[0].points.geojson)
    let voyagePlannerPoints = updatedVesselRoutes[0].points.geojson
    EtaCalculatorFlask({
      FLASK_URL,
      accessToken,
      voyagePlannerPoints,
      calculatorDepartureTime,
      calculatorBasespeed,
      setEtaResponse,
      setPathfinderRoutes,
    })
    // Set the updated vessel routes
    setVesselRoutes(updatedVesselRoutes)
  }

  return vesselRoutes === null ? null : (
    <LayerGroup>
      {vesselRoutes.map((route) => {
        let routeLine = route.lines
        let lineGeojson = routeLine.geojson

        return Object.keys(routeLine).length === 0 ? null : (
          <>
            {/* Render the white stroke */}
            <GeoJSON
              key={`RouteLines-Stroke-${routeLine.routeId}-${mapBounds}-${JSON.stringify(routeLine.geojson.features)}`}
              interactive={true}
              data={lineGeojson}
              style={(feature) => getStyle({ feature, routeType: routeLine.routeType, isStroke: true })}
              eventHandlers={
                routeLine.routeType === "Pathfinder"
                  ? {
                      mousemove: (e) => handleMouseMove(e, e.sourceTarget.feature),
                      mouseout: handleMouseOut,
                    }
                  : {} // Empty object to disable event handlers for other route types
              }
            />
            {/* Render the colored line on top */}
            <GeoJSON
              key={`RouteLines-Line-${routeLine.routeId}-${mapBounds}-${JSON.stringify(routeLine.geojson.features)}`}
              interactive={true}
              data={lineGeojson}
              style={(feature) => getStyle({ feature, routeType: routeLine.routeType, isStroke: false })}
              eventHandlers={
                routeLine.routeType === "Pathfinder"
                  ? {
                      mousemove: (e) => handleMouseMove(e, e.sourceTarget.feature),
                      mouseout: handleMouseOut,
                      click: (e) => handleLineClick(e, e.sourceTarget.feature, route),
                    }
                  : {} // Empty object to disable event handlers for other route types
              }
            />
          </>
        )
      })}

      {/* Render the hover marker if set */}
      {hoverMarker && (
        <Marker key={`hoverMarker-${hoverMarker}`} position={hoverMarker} icon={markerIcon} pane="markerPane" />
      )}
    </LayerGroup>
  )
}
